import { Assessment } from "../../types/Assessment";
import {
  convertDateToString,
  convertDateToTimestamp,
} from "../../utils/dateUtils";
import renderAdaptiveCard from "../../utils/renderAdaptiveCard";
import { startTeamsTask } from "../../utils/teamsTasks";

import AssessmentCard from "./AssessmentCard.json";

const openAssessmentCard = async (
  assessment?: Assessment,
  mode: "ADD" | "EDIT" = "ADD"
) => {
  let assessmentCardData = {};
  if (assessment) {
    // converting object value to appropriate card value
    assessmentCardData = {
      groupName: assessment.groupName,
      landingPageInfo: assessment.landingPageInfo,
      startsAt: new Date(+assessment.startsAt).toString(),
      expiresAt: new Date(assessment.expirationDate).toString(),
      interviewType: assessment.groupConfig.interviewType,
      responseVisibility: `${assessment.responseVisibility}`,
    };
  }
  const adpCardJSON = renderAdaptiveCard(AssessmentCard, assessmentCardData);

  const taskInfo = {
    title: mode === "ADD" ? "Create New Assessment" : "Edit Assessment",
    card: adpCardJSON,
  };
  const result = await startTeamsTask(taskInfo || {});
  const responseData = {
    // default value
    groupName: result.groupName,
    landingPageInfo: result.landingPageInfo,
    startsAt: convertDateToTimestamp(result.startsAt).toString(),
    expiresAt: convertDateToTimestamp(result.expiresAt).toString(),
    expirationDate: convertDateToString(result.expiresAt),
    groupConfig: { interviewType: result.interviewType },
    responseVisibility: result.responseVisibility === "true",

    // Not accepted form user
    enableReminder: assessment?.enableReminder || false,
    reminderInterval: assessment?.reminderInterval || null,
    maxReminders: assessment?.maxReminders || null,
    enableBanner: assessment?.enableBanner || false,
    enableInclusiveHiring: assessment?.enableInclusiveHiring || false,
  };
  return responseData;
};

export default openAssessmentCard;
