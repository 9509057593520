import React, { useEffect, useState } from "react";
import { getAuthToken } from "../../utils/authTokenUtils";
import { baseAppURL } from "../../utils/constants";

import { Text } from "@fluentui/react-northstar";
import { getXToken } from "../../api/xtoken";
import { Toolbar } from "@fluentui/react-teams";
import useLoader from "../../hooks/useLoader";
import FullScreenLoader from "../../components/FullScreenLoading";
import logger from "../../services/logger";

function CandidateDetail({ candidateId, goBack }: { candidateId: string; goBack: Function }) {
  const [xrtoken, setXRToken] = useState(null);
  const { stopLoading, isLoading, loadingMessage } = useLoader({
    isLoading: true,
    message: "Loading",
  });

  const _getXRToken = async () => {
    try {
      const res = await getXToken();
      const { t } = res || {};
      setXRToken(t);
    } catch (error) {
      logger.error("Error while fetching XR token ", error);
    }
    stopLoading();
  };

  useEffect(() => {
    _getXRToken();
  }, []);

  const handleInteraction = (e: any) => {
    switch (e.subject) {
      case "back":
        // Go back to Assessments detail
        goBack();
        break;
      default:
        logger.debug("Not handled actions");
        break;
    }
  };

  const toolbarProps = {
    actionGroups: {
      g1: {
        a1: {
          title: `Assessment`,
          subject: "back",
          icon: "ArrowLeft",
        },
      },
    },
    onInteraction: (a: any) => {
      handleInteraction(a);
    },
  };

  if (isLoading) return <FullScreenLoader label={loadingMessage} />;

  if (!xrtoken || !candidateId) {
    return <Text>Fail to Get Authentication Token </Text>;
  }

  return (
    <div>
      <Toolbar {...toolbarProps} />
      <iframe
        title="Candidate Response"
        id="candidate-response"
        width="100%"
        frameBorder="0"
        height={`${window.innerHeight}`}
        src={`${baseAppURL}/pt/xt/room/${candidateId}?xrt=${xrtoken}`}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default CandidateDetail;
