import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/appSlice";
import assessmentReducer from "./slices/assessmentSlice";

import candidateReducer from "./slices/candidateSlice";
import questionReducer from "./slices/questionSlice";

// ConfigureStore function internally sets up the thunk middleware
const store = configureStore({
  reducer: {
    app: appReducer,
    question: questionReducer,
    candidate: candidateReducer,
    assessment: assessmentReducer,
  },
  devTools: process.env.REACT_APP_ENV === "dev",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
