import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Question = {
  hybridQuestionId: string;
  assessmentId: string;
  id: string;
  hybridQuestionName: string;
  hybridQuestionType: string;
  category: any[];
  criteria: any[];
  hybridPrepTime: number;
  hybridMaxAnswerTime: number;
  hybridMaxRetakeTime: number;
  hybridRetakeDecisionTime: number;
  hybridMaxWords: number;
  hybridMinWords: number;
};

const initialState: { questions: Question[] } = {
  questions: [],
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setQuestions(state, action) {
      state.questions = action.payload;
    },
    addQuestions(state, actions) {
      state.questions = [...state.questions, ...actions.payload];
    },
    addQuestion({ questions }, { payload }) {
      questions.push(payload);
    },
    updateQuestions(state, { payload }) {
      const id = state.questions.findIndex((q) => q.id === payload.id);
      state.questions[id] = { ...state.questions[id], ...payload };
      return state;
    },
    removeQuestions(state, action) {
      state.questions = state.questions.filter(
        (q) => q.hybridQuestionId !== action.payload
      );
      return state;
    },
  },
});

export default questionSlice.reducer;

export const questionActions = { ...questionSlice.actions };

export const questionSelector = {
  questions: (assessmentId: string) => (store: RootState) =>
    store.question.questions.filter((q) => +q.assessmentId === +assessmentId),
};
