import React from "react";
import { Flex, Card, Text, RadioGroup, CalendarIcon, Divider } from "@fluentui/react-northstar";
import QuestionList from "./QuestionList";
import { Assessment } from "../../types/Assessment";
import dateUtils from "../../utils/dateUtils";

const AssessmentSetup = ({
  assessment,
  questionCriteria,
}: {
  assessment: Assessment;
  questionCriteria: any;
}) => {
  return (
    <Flex column className="pt-3">
      <Flex column className="px-3">
        <Text weight="bold" size="medium" content="Settings" />
        <div className="p-3">
          <Flex column className="p-1">
            <Text weight="semibold" content="Interview Type" />
            <RadioGroup
              vertical
              className="pt-2"
              checkedValue={assessment.groupConfig.interviewType}
              items={[
                {
                  key: "fixed",
                  label: "Fixed",
                  value: "fixed",
                  disabled: assessment.groupConfig.interviewType !== "fixed",
                },
                {
                  key: "random",
                  label: "Random",
                  value: "random",
                  disabled: assessment.groupConfig.interviewType !== "random",
                },
              ]}
            />
          </Flex>

          <Flex column className="pt-4">
            <Text
              weight="semibold"
              content="Allow candidates to see responses after submittion of assessment?"
            />
            <RadioGroup
              className="pt-2"
              vertical
              checkedValue={assessment.responseVisibility ? "yes" : "no"}
              items={[
                {
                  key: "yes",
                  label: "Yes",
                  value: "yes",
                  disabled: !assessment.responseVisibility,
                },
                {
                  key: "no",
                  label: "No",
                  value: "no",
                  disabled: assessment.responseVisibility,
                },
              ]}
            />
          </Flex>

          <Flex gap="gap.large" wrap={true}>
            <Flex column className="pt-4">
              <Text weight="semibold" className="mb-2" content="Start Date" />
              <Card size="small" inverted>
                <Flex space="between" className="p-2">
                  <Text>{dateUtils.getDateTimeString(+assessment.startsAt)}</Text>
                  <CalendarIcon />
                </Flex>
              </Card>
            </Flex>

            <Flex column className="pt-4">
              <Text weight="semibold" className="mb-2" content="End Date" />
              <Card size="small" inverted>
                <Flex space="between" className="p-2">
                  <Text content={dateUtils.getDateTimeString(assessment.expirationDate)} />
                  <CalendarIcon />
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <Divider className="my-3" />
      <QuestionList assessment={assessment} questionCriteria={questionCriteria} />
    </Flex>
  );
};

export default AssessmentSetup;
