import * as microsoftTeams from "@microsoft/teams-js";

export const getTeamsContext = (): Promise<microsoftTeams.Context> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        if (context) resolve(context);
        else reject(new Error("Unable to get teams context"));
      });
    });
  });
};

export const executeDeepLink = (deepLink: string) => {
  microsoftTeams.executeDeepLink(deepLink);
};

export const checkInTeams = (): boolean => {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame"
  ) {
    return true;
  }
  return false;
};
