import moment from "moment";

export const convertDateToTimestamp = (value: string | Date | number) => {
  const dt = new Date(value).getTime();
  return dt;
};

export const convertDateToString = (value: string | Date | number) => {
  const dt = new Date(value);
  return dt.toISOString();
};

export const getYesterdayDate = () => {
  const date = new Date();
  return date.setDate(date.getDate() - 1);
};

const getDateTimeString = (value: string | Date | number) => {
  return moment(value).format("DD/MM/YYYY, h:mm a");
};

const dateUtils = {
  convertDateToString,
  convertDateToTimestamp,
  getYesterdayDate,
  getDateTimeString,
};

export default dateUtils;
