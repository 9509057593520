import * as microsoftTeams from "@microsoft/teams-js";

export const startTeamsTask = (taskInfo: any) => {
  return new Promise<any>((resolve, reject) => {
    const submitHandler = (err: string, result: string) => {
      if (err) {
        reject(err.toString());
      } else resolve(result);
    };
    microsoftTeams.tasks.startTask(taskInfo, submitHandler);
  });
};
