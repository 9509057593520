import moment from "moment";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import ProgressBar from "@ramonak/react-progress-bar";
import { Text, Flex, Label, Button, AddIcon } from "@fluentui/react-northstar";

import logger from "../../services/logger";
import useAssessments from "./useAssessments";
import { Assessment } from "../../types/Assessment";
import { calculatePercentage } from "../../utils/math";
import { getTeamsContext } from "../../utils/teamsUtils";
import CustomList, { TColumn } from "../../components/CustomList";
import FullScreenLoader from "../../components/FullScreenLoading";
import AssessmentDetails from "../../sections/assessment/AssessmentDetails";
import {
  PAGE_STATE_EXPIRATION_TIME,
  SELECTED_ASSESSMENT_ID,
} from "../../utils/constants";
import withGraphAuthentication from "../../services/graphAPI/withGraphAuthentication";
import yTCard from "../../adaptiveCards/yt.json";

const TODAY = new Date();

const Assessments = () => {
  const { t } = useTranslation("translation", { keyPrefix: "assessment" });

  const {
    assessments,
    fetchAssessments,
    loader,
    addNewAssessments,
    editAssessment,
    closeAssessments,
  } = useAssessments();

  const [selectedAssessment, setSelectedAssessment] = useState<
    Assessment | null | undefined
  >(undefined);

  const selectAssessmentById = async (
    assessments: Assessment[],
    id: string
  ) => {
    const assessment = assessments.find(
      (a) => a.videointerviewGroupId + "" === id
    );
    assessment && setSelectedAssessment(assessment);
  };

  const removeAssessmentIDFromLocalStorage = () => {
    localStorage.removeItem(SELECTED_ASSESSMENT_ID);
    localStorage.removeItem(PAGE_STATE_EXPIRATION_TIME);
  };

  const loadAssessments = async () => {
    loader.startLoading("Loading Data...");
    const assessmentFetched = await fetchAssessments();
    const context = await getTeamsContext();
    if (context?.subEntityId) {
      selectAssessmentById(assessmentFetched, context?.subEntityId);
      loader.stopLoading();
      return;
    }

    const selectedAssessmentId = localStorage.getItem(SELECTED_ASSESSMENT_ID);
    const expirationTime = localStorage.getItem(PAGE_STATE_EXPIRATION_TIME);
    if (expirationTime && selectedAssessmentId) {
      removeAssessmentIDFromLocalStorage();
      const dateTime = moment(expirationTime);
      if (dateTime > moment()) {
        selectAssessmentById(assessmentFetched, selectedAssessmentId);
      }
    }
    loader.stopLoading();
  };

  useEffect(() => {
    loadAssessments();

    // Call At the time unload
    microsoftTeams.registerBeforeUnloadHandler((readyToUpload) => {
      localStorage.removeItem("selectedAssessmentId");
      localStorage.removeItem("pageStateExpirationTime");
      readyToUpload();
      return true;
    });
  }, []);

  useEffect(() => {
    if (selectedAssessment) {
      logger.debug("Save selected assessment id inside local Storage");
      localStorage.setItem(
        SELECTED_ASSESSMENT_ID,
        "" + selectedAssessment?.videointerviewGroupId
      );
      localStorage.setItem(
        PAGE_STATE_EXPIRATION_TIME,
        moment().add(15, "m").toISOString()
      );
    } else if (selectedAssessment === null) {
      logger.debug("Remove selected assessment id from local Storage");
      removeAssessmentIDFromLocalStorage();
    }
  }, [selectedAssessment]);

  const rowActions = {
    edit: {
      title: t("action.editAssessment"),
      icon: "Edit",
      onClick: (id: string) => editAssessment(+id),
    },
    close: {
      title: t("action.closeAssessment"),
      icon: "Close",
      onClick: (id: string) => closeAssessments([+id] as number[]),
    },
    // "add-question": { title: "Add Questions", icon: "ToDoList", onClick: () => {} },
    // "add-candidate": { title: "Add Candidate", icon: "ParticipantAdd", onClick: () => {} },
    // "clone-assessment": { title: "Clone Assessment", icon: "WindowRestore" },
    // "bulk-candidate-import": { title: "Bulk Candidate Import", icon: "Edit" },
  };

  const getItemNumber = (value: number | string, total: number | string) => {
    return {
      content: (
        <Flex>
          <Flex column hAlign="center">
            <Text weight="bold" style={{ paddingBottom: "4px" }}>
              {value}
            </Text>
            <ProgressBar
              completed={calculatePercentage(+value, +total)}
              customLabel={" "}
              height="5px"
              width="30px"
              bgColor="#228B22"
            />
          </Flex>
        </Flex>
      ),
    };
  };

  const getStatus = (expirationDate: any) => {
    return new Date(expirationDate) < TODAY ? "close" : "open";
  };

  const handleAssessmentCLick = (e: any, assessment: Assessment) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedAssessment(assessment);
  };

  const mapAssessmentToListRow = (assessment: Assessment) => {
    const {
      "Awaiting response": awaitingResponse,
      Completed,
      Draft,
      Total,
      inprogress,
    } = {
      "Awaiting response": 0,
      Completed: 0,
      Draft: 0,
      Total: 0,
      inprogress: 0,
      ...assessment?.statusMap,
    };

    const rowData: any = {
      assessmentName: {
        content: (
          <Flex
            column
            fill
            className="cursor-pointer"
            onClick={(e: any) => handleAssessmentCLick(e, assessment)}
          >
            <Text weight="bold" color="brand">
              {assessment.groupName}
            </Text>
            <Flex gap="gap.medium">
              <Label
                circular
                color="grey"
                style={{
                  padding: "0px 12px",
                  height: "18px",
                  marginTop: "4px",
                }}
              >
                <Text
                  size="small"
                  content={`ID: ${assessment.videointerviewGroupId}`}
                />
              </Label>
              <Label
                circular
                color="grey"
                style={{
                  padding: "0px 12px",
                  height: "18px",
                  marginTop: "4px",
                }}
              >
                <Text
                  size="small"
                  content={getStatus(assessment.expirationDate).toUpperCase()}
                />
              </Label>
            </Flex>
          </Flex>
        ),
      },
      total: getItemNumber(Total, Total),
      awaitingResponse: getItemNumber(awaitingResponse, Total),
      completed: getItemNumber(Completed, Total),
      draft: getItemNumber(Draft, Total),
      inProgress: getItemNumber(inprogress, Total),
    };
    if (getStatus(assessment.expirationDate) === "close") {
      rowData.actions = { edit: rowActions.edit };
    }

    return rowData;
  };

  if (loader.isLoading) {
    return <FullScreenLoader label={loader.message} />;
  }

  if (selectedAssessment) {
    return (
      <AssessmentDetails
        assessmentId={selectedAssessment.videointerviewGroupId}
        goBack={() => setSelectedAssessment(null)}
      />
    );
  }

  const columns: TColumn = {
    assessmentName: {
      title: t("columnName.assessmentName"),
      textSelectable: true,
    },
    total: {
      title: t("columnName.total"),
      minWidth: 150,
    },
    awaitingResponse: {
      title: t("columnName.awaitingResponse"),
      minWidth: 150,
    },
    completed: {
      title: t("columnName.completed"),
      minWidth: 150,
    },
    draft: {
      title: t("columnName.draft"),
      minWidth: 150,
    },
    inProgress: {
      title: t("columnName.inProgress"),
      minWidth: 150,
    },
  };

  return (
    <>
      <CustomList
        // loading={true}
        items={assessments}
        itemIdKeyName="id"
        columns={columns}
        renderRow={mapAssessmentToListRow}
        toolbarActions={{
          create: {
            title: t("action.createAssessment"),
            icon: "Add",
            onClick: addNewAssessments,
          },
        }}
        rowActions={rowActions}
        findComparator={(item: Assessment, findString) =>
          item.groupName.toLowerCase().includes(findString.toLowerCase())
        }
        filters={[
          {
            id: "scope",
            title: t("label.scope"),
            items: [
              {
                id: "open",
                title: t("filter.open"),
              },
              {
                id: "close",
                title: t("filter.closed"),
              },
            ],
          },
        ]}
        onSelectedFiltersChange={(item, selectedFilter) => {
          const status = getStatus(item.expirationDate);
          return selectedFilter.includes(status);
        }}
      />
    </>
  );
};

export default withGraphAuthentication(Assessments);
