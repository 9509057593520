import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, OpenOutsideIcon, Text } from "@fluentui/react-northstar";

import { ON_BOARD_LINK } from "../utils/constants";

function UserNotRegistered() {
  const { t } = useTranslation("translation", { keyPrefix: "userNotRegistered" });

  return (
    <Flex style={{ paddingTop: "30vh" }} column hAlign="center">
      <Text weight="semibold" size="large">
        {t("notRegisterErrorMessage")}
      </Text>
      <Text weight="light" className="mt-1">
        {t("userGuidance")}
      </Text>
      <Button
        primary
        as="a"
        href={ON_BOARD_LINK}
        target="_blank"
        className="mt-2"
        icon={<OpenOutsideIcon />}
        content={t("register")}
      />
      <Text size="smaller" weight="light" className="mt-1">
        Note: Clicking on this button will take you outside the teams
      </Text>
    </Flex>
  );
}

export default UserNotRegistered;
