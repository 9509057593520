import logger from "../services/logger";
import { xfetch } from "../services/xfetch";

const getXToken = async () => {
  try {
    return await xfetch("xtoken", "post", null);
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export { getXToken };
