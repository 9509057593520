import Axios, { AxiosRequestConfig } from "axios";
import { TeamsApp } from "microsoft-graph";
import { config } from "../../Config";
import { getTeamsContext } from "../../utils/teamsUtils";
import useGraphClient from "./useGraphClient";
import { ErrorCode } from "../../utils/constants";
import logger from "../logger";

type SendActivityFeedProps = {
  activityType: string;
  receiverEmail: string;
  message: string;
  entityId: string;
  subEntityId: string;
  content: string;
  deepLink: string;
};

function useGraphService() {
  const { client, getApplicationLevelClient } = useGraphClient();

  const _getCatalogApps = async (appId: string): Promise<TeamsApp | undefined> => {
    const results = await client
      ?.api(`/appCatalogs/teamsApps?$filter=externalId eq '${appId}'`)
      .get();
    return results && results.value && results.value.length === 1
      ? (results.value[0] as TeamsApp)
      : undefined;
  };

  const _getUserInformationByEmail = async (email: string) => {
    return await client?.api(`/users/${email}`).get();
  };

  const _sendActivityFeed = async ({
    userId,
    deepLink,
    entityId,
    subEntityId,
    activityType,
    content,
    message,
  }: any) => {
    const encodedWebUrl = encodeURI(deepLink);
    const encodedContext = encodeURI(`{"subEntityId": "${subEntityId}"}`);
    const teamsApp = await _getCatalogApps(config.appId);

    const taskItemUrl =
      `https://teams.microsoft.com/l/entity/${teamsApp?.id}/${entityId}?webUrl=` +
      encodedWebUrl +
      "&context=" +
      encodedContext;

    const xdata = {
      topic: {
        source: "text",
        value: "X0PA AI",
        webUrl: taskItemUrl,
      },
      activityType,
      previewText: {
        content,
      },
      templateParameters: [
        {
          name: "description",
          value: message,
        },
      ],
    };
    await client?.api(`/users/${userId}/teamwork/sendActivityNotification`).post(xdata);
  };

  const _installAppToUser = async (userId: string) => {
    if (!userId) throw new Error("No user id");

    const appLevelClient = getApplicationLevelClient([
      "TeamsAppInstallation.ReadWriteSelfForUser.All",
    ]);
    if (!appLevelClient) {
      const error = {
        code: ErrorCode.APPLICATION_PERMISSION_NOT_GRANTED,
        message:
          "Not able to install the application because application permission is not Granted",
      };
      throw error;
    }

    const teamsApp = await _getCatalogApps(config.appId);
    const userScopeTeamsAppInstallation = {
      "teamsApp@odata.bind": `https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/${teamsApp?.id}`,
    };

    await appLevelClient
      ?.api(`/users/${userId}/teamwork/installedApps`)
      .post(userScopeTeamsAppInstallation);
    logger.info("Successfully installed the app");
  };

  async function sendActivityFeed({
    activityType,
    receiverEmail,
    message,
    subEntityId,
    entityId,
    content,
    deepLink,
  }: SendActivityFeedProps) {
    let userId = null;
    try {
      let { id } = await _getUserInformationByEmail(receiverEmail);
      userId = id;

      await _sendActivityFeed({
        userId,
        deepLink,
        activityType,
        content,
        message,
        entityId,
        subEntityId,
      });
    } catch (err) {
      if ((err as any).statusCode === 403) {
        // Application is not install on the recevicer side
        await _installAppToUser(userId);
        await _sendActivityFeed({
          userId,
          deepLink,
          activityType,
          content,
          message,
          entityId,
          subEntityId,
        });
        logger.info("Sent the notification");
      } else {
        logger.error("Error while activity feed notification  ", err);
      }
    }
  }

  const sendChannelNotification = async (
    receiverEmail: string,
    assessment: {
      id: string;
      name: string;
      imageUrl: string;
      landingPageInfo: string;
      createdAt: string;
    }
  ) => {
    try {
      const context = await getTeamsContext();
      const receiver = await _getUserInformationByEmail(receiverEmail);
      const sender = await _getUserInformationByEmail(context.userPrincipalName as string);

      const teamsApp = await _getCatalogApps(config.appId);
      var data = JSON.stringify({
        xaction: "sendChannelNotification",
        xdata: {
          webUrl: window.location.href,
          entityId: context.entityId,
          channelId: context.channelId,
          installationAppId: teamsApp?.id,
          sender: {
            name: sender.displayName,
            id: sender.id,
          },
          addedCollaborator: {
            name: receiver.displayName,
            id: receiver.id,
          },
          assessment,
        },
      });

      var options: AxiosRequestConfig = {
        method: "post",
        url: `${config.botUrl}/api/notify`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await Axios(options);
    } catch (error) {
      logger.error("Error while sending Notification, ", error);
      return false;
    }
    return true;
  };

  const getChannelMembers = async () => {
    const { channelId } = await getTeamsContext();
    if (!channelId) return [];

    var options: AxiosRequestConfig = {
      method: "get",
      url: `${config.botUrl}/api/channelMember/${channelId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await Axios(options);
      return response.data;
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  return {
    sendActivityFeed,
    sendChannelNotification,
    getChannelMembers,
    client,
  };
}

export default useGraphService;
