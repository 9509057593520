import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// import i18n (needs to be bundled ;))

import "./index.scss";

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
