const checkStringIncludeInTexts = (searchText: string, texts: string[]) => {
  searchText = searchText.toLowerCase();
  const result = texts.find((t) => t.toLowerCase().includes(searchText));
  return !!result;
};

const convertToTitleCase = (text: string | null) => {
  if (!text) return text;
  return text
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
};

const utils = {
  checkStringIncludeInTexts,
  convertToTitleCase,
};

export default utils;
