import { createSlice } from "@reduxjs/toolkit";
import {
  SnackbarDuration,
  SnackbarType,
} from "../../components/Snackbar/Snackbar";

type AppSliceType = {
  snackbar: {
    show: boolean;
    message: string;
    duration: SnackbarDuration;
    type: SnackbarType;
  };
};

const initialState: AppSliceType = {
  snackbar: {
    show: false,
    message: "",
    duration: "MEDIUM",
    type: "INFO",
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.snackbar = {
        ...action.payload,
        show: true,
      };
    },
    hideSnackbar: (state) => {
      state.snackbar = initialState.snackbar;
    },
  },
});

export const appActions = { ...appSlice.actions };

export default appSlice.reducer;
