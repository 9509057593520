import React, { useEffect } from "react";
import { Text } from "@fluentui/react-northstar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ROUTES from "./routes";
import logger from "../services/logger";
import Welcome from ".././sections/Welcome";
import XPlay from ".././sections/videoPlayer/XPlay";
import PageNotFound from "../sections/PageNotFound";
import Assessments from ".././features/AssessmentTab";
import RemoveTab from ".././features/ChannelTab/RemoveTab";
import ChannelTab from "../features/ChannelTab/ChannelTab";
import PrivateRouteContainer from "./PrivateRouteContainer";
import ChannelTabConfig from "../features/ChannelTab/ChannelTabConfig";
import { useAuthentication } from "../services/authenticator/AuthProvider";

function Navigator() {
  const authProps = useAuthentication();

  useEffect(() => {
    logger.info("Visited Routes: ", window.location.href);
  }, []);
  const privateRoutes = () => {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path={ROUTES.root}
            render={() => <Welcome authButtonMethod={authProps.login} />}
          />
          <Route exact path={ROUTES.assessmentTab} render={() => <Assessments />} />
          <Route
            exact
            path={ROUTES.ratingCriteriaTab}
            render={() => <Text>Rating Criteria</Text>}
          />
          <Route exact path={ROUTES.questionBankTab} render={() => <Text>Question Bank</Text>} />

          <Route exact path={ROUTES.configChannelTab} render={() => <ChannelTabConfig />} />

          <Route exact path={ROUTES.channelTab} render={() => <ChannelTab />} />

          <Route path="*" render={() => <PageNotFound />} />
        </Switch>
      </Router>
    );
  };

  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.xPlay} render={(props) => <XPlay {...props} />} />
        <Route exact path={ROUTES.removeChannelTab} render={() => <RemoveTab />} />

        {/* Place all the public route about the Private Routes */}
        <PrivateRouteContainer>{privateRoutes()}</PrivateRouteContainer>
      </Switch>
    </Router>
  );
}

export default Navigator;
