import { duration } from "moment";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  SnackbarDuration,
  SnackbarType,
} from "../components/Snackbar/Snackbar";
import { appActions } from "../store/slices/appSlice";

export default function useSnackbar() {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      (
        message: string,
        type: SnackbarType = "INFO",
        duration: SnackbarDuration = "MEDIUM"
      ) =>
        dispatch(appActions.showSnackbar({ message, type, duration })),
    [dispatch]
  );
}
