import * as React from "react";
import { useTranslation } from "react-i18next";
import * as MicrosoftTeams from "@microsoft/teams-js";
import { Flex, Text } from "@fluentui/react-northstar";

export default function RemoveTab() {
  const { t } = useTranslation("translation", { keyPrefix: "removeTab" });
  React.useEffect(() => {
    MicrosoftTeams.initialize();
    MicrosoftTeams.settings.registerOnRemoveHandler((removeEvent) => {
      // Here you can designate the tab content to be removed and/or archived.
      // MicrosoftTeams.settings.getSettings((settings) => {
      //   // settings.contentUrl = "...";
      // });
      removeEvent.notifySuccess();
    });
  }, []);

  return (
    <Flex column hAlign="center" className="pt-4">
      <Text size="larger" weight="semibold">
        {t("warningMessage")}
      </Text>
      <Text className="pt-2">{t("information")}</Text>
    </Flex>
  );
}
