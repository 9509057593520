import React, { FC } from "react";

import Welcome from "../sections/Welcome";
import UserNotRegistered from "../sections/UserNotRegistered";
import { useAuthentication } from "../services/authenticator/AuthProvider";

const PrivateRouteContainer: FC = ({ children }) => {
  const { login, isAuthenticated, isUserRegistered } = useAuthentication();

  if (!isAuthenticated) return <Welcome authButtonMethod={login} />;

  if (isUserRegistered === false) return <UserNotRegistered />;

  return <>{children}</>;
};

export default PrivateRouteContainer;
