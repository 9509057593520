import React, { useLayoutEffect, useRef } from "react";
import videojs from "video.js";
import "./vjs.css"; // has font-family overrides
import "./vjs-forest.css";

const VideoPlayer = (videoJsOptions) => {
  const videoNodeRef = useRef(null);
  const key = videoJsOptions.sources[0].src;
  useLayoutEffect(() => {
    const player = videojs(videoNodeRef.current, videoJsOptions, () => {});
    return () => {
      if (player) {
        player.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <div className="xr--video--player">
      <div data-vjs-player key={key}>
        <video ref={videoNodeRef} className="video-js vjs-theme-forest"></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
