import * as microsoftTeams from "@microsoft/teams-js";
import React, { FC, useCallback, useEffect, useState } from "react";

import logger from "../logger";
import userAPI from "../../api/userAPI";
import useLoader from "../../hooks/useLoader";
import { getAuthToken } from "../../utils/authTokenUtils";
import FullScreenLoader from "../../components/FullScreenLoading";
import { checkInTeams, getTeamsContext } from "../../utils/teamsUtils";

const AuthContext = React.createContext({
  inTeams: false,
  isAuthenticated: false,
  isUserRegistered: false,
  login: () => {},
  logout: () => {},
});

export const useAuthentication = () => React.useContext(AuthContext);

const CacheLoginStatus = {
  login: () => window.localStorage.removeItem("signedOut"),
  logout: () => window.localStorage.setItem("signedOut", "true"),
  checkIsLoginIn: () => window.localStorage.getItem("signedOut") !== "true",
};

const AuthProvider: FC = ({ children }) => {
  const [inTeams, setInTeams] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const { isLoading, stopLoading, startLoading, loadingMessage } = useLoader({
    isLoading: true,
    message: "Loading...",
  });

  useEffect(() => {
    const initialize = async () => {
      microsoftTeams.initialize();

      if (!checkInTeams()) {
        logger.debug("Not Inside Microsoft Teams");
        setInTeams(false);
        stopLoading();
        return;
      }
      logger.debug("Inside Microsoft Teams");
      setInTeams(true);
      const context = await getTeamsContext();
      logger.debug("Context: ", context);

      if (CacheLoginStatus.checkIsLoginIn()) {
        login();
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async () => {
    if (!checkInTeams()) return;

    startLoading("Signing...");
    try {
      // SSO SignIn
      await getAuthToken();
      setIsAuthenticated(true);
      try {
        await userAPI.getUserId();
        setIsUserRegistered(true);
      } catch (error) {
        setIsUserRegistered(false);
      }
      CacheLoginStatus.login();
    } catch (err) {
      logger.error(err);
      setIsAuthenticated(false);
    }
    stopLoading();
  }, [startLoading, stopLoading]);

  const logout = useCallback(() => {
    CacheLoginStatus.logout();
    setIsAuthenticated(false);
  }, []);

  if (isLoading) {
    return <FullScreenLoader label={loadingMessage} />;
  }

  return (
    <AuthContext.Provider value={{ inTeams, isAuthenticated, isUserRegistered, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
