import { Loader } from "@fluentui/react-northstar";
import {
  IColumn,
  ITableProps,
  IToolbarProps,
  Table,
  TAction,
  Toolbar,
  TTableInteraction,
  TToolbarInteraction,
  CommunicationOptions,
  Communication,
} from "@fluentui/react-teams";
import { TFilters } from "@fluentui/react-teams/lib/cjs/components/Toolbar/Toolbar";
import React, { useCallback, useEffect, useState } from "react";

interface IToolbarAction {
  title: string;
  icon: string;
  onClick?: () => any;
}

export type TRowActions = {
  [columnKey: string]: TAction & { onClick?: (id: string) => void };
};

export type TColumn = {
  [columnKey: string]: IColumn;
};

export type TCustomList = {
  items: any[] | undefined;
  columns: TColumn;
  renderRow: (item: any) => {};
  itemIdKeyName: string;

  toolbarActions: {
    [columnKey: string]: IToolbarAction;
  };

  rowActions: TRowActions;

  loading?: boolean;
  loadingMessage?: string;

  findComparator?: (item: any, findString: string) => boolean;

  /**
   * The filters to make available in the Toolbar.
   */
  filters?: TFilters;
  onSelectedFiltersChange?: (item: any, selectedFilters: string[]) => boolean;
};

function CustomList({
  items: data,
  columns,
  renderRow,
  itemIdKeyName,
  toolbarActions,
  rowActions,
  loading,
  loadingMessage,
  filters,
  onSelectedFiltersChange,
  findComparator,
}: TCustomList) {
  const [items, setItems] = useState(data);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>();

  const tableConfig: ITableProps = {
    columns,
    label: "List",
    rows:
      items?.reduce(
        (acc, item) => ({
          ...acc,
          [item[itemIdKeyName]]: { actions: rowActions, ...renderRow(item) },
        }),
        {}
      ) || {},
    onInteraction: (e: TTableInteraction) => {
      const id = (e.subject instanceof Array ? e.subject[0] : e.subject) as string;
      if (e.action && id) rowActions[e.action]?.onClick?.(id);
    },
  };

  const combineComparator = useCallback(
    (item: string, filters: string[], findQuery?: string) => {
      let findResult = false;
      let filterResult = false;

      if (findQuery && findComparator) findResult = findComparator(item, findQuery);
      else findResult = true;

      if (filters.length !== 0 && onSelectedFiltersChange)
        filterResult = onSelectedFiltersChange(item, filters);
      else filterResult = true;

      return findResult && filterResult;
    },
    [findComparator, onSelectedFiltersChange]
  );

  useEffect(() => {
    if (selectedFilters.length !== 0 || searchString) {
      setItems(data?.filter((item) => combineComparator(item, selectedFilters, searchString)));
    } else {
      setItems(data);
    }
  }, [selectedFilters, searchString, data, combineComparator]);

  const toolbarConfig: IToolbarProps = {
    actionGroups: {
      groupOne: toolbarActions,
    },
    onInteraction: (interaction: TToolbarInteraction) => {
      toolbarActions[interaction.action].onClick?.();
    },
    find: !!findComparator,
    onFindQueryChange: (queryString) => {
      setSearchString(queryString);
      return queryString;
    },

    filters,
    onSelectedFiltersChange: (selectedFilter) => {
      setSelectedFilters(selectedFilter);
      return selectedFilter;
    },
  };

  const renderTable = () => {
    if (items && items.length !== 0) {
      return <Table {...tableConfig} />;
    } else {
      return (
        <Communication
          option={CommunicationOptions.Empty}
          fields={{
            title: "Create your first list item",
            desc: "Once you are assigned a task, you can find it here. Until then, enjoy your time off.",
          }}
        />
      );
    }
  };

  return (
    <div style={{ minHeight: "400px" }}>
      <Toolbar {...toolbarConfig} />
      {loading ? <Loader label={loadingMessage} /> : renderTable()}
    </div>
  );
}

export default CustomList;
