import moment from "moment";
import { uuid } from "uuidv4";
import * as MicrosoftTeams from "@microsoft/teams-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  List,
  Image,
  Text,
  useFluentContext,
  Flex,
  SearchIcon,
  Input,
} from "@fluentui/react-northstar";
import { Communication, CommunicationOptions } from "@fluentui/react-teams";

import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import assessmentAPI from "../../api/assessmentAPI";
import { saveTabContext } from "../../api/tabcontext";
import { getTeamsContext } from "../../utils/teamsUtils";
import FullScreenLoader from "../../components/FullScreenLoading";
import { useTranslation } from "react-i18next";

const ChannelTabConfig = () => {
  const selectedAssessmentId = useRef();
  const searchTextInputId = "searchTextTabConfigScreen";

  const { theme } = useFluentContext();
  const [items, setItems] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { t } = useTranslation("translation", { keyPrefix: "channelTabConfig" });
  const { t: commonT } = useTranslation("translation", { keyPrefix: "common" });

  const fetchAssessments = async () => {
    try {
      startLoading("Loading Assessments...");
      const { Videointerviewgroups } = await assessmentAPI.listAssessments();
      const items = [];
      if (Array.isArray(Videointerviewgroups)) {
        Videointerviewgroups.forEach((vi) => {
          const { videointerviewGroupId, groupName, statusMap, groupConfig, createdAt } = vi || {};
          const { Total, Completed, Draft, inprogress } = statusMap || {};
          const { interviewType } = groupConfig || {};
          const iconType = interviewType === "fixed" ? "pin-174.png" : "shuffle-274.png";
          const mediaImage = `https://xpastatic.blob.core.windows.net/xassetspub/teams/${iconType}`;
          items.push({
            key: videointerviewGroupId,
            groupName,
            header: <Text weight="semibold">{groupName}</Text>,
            media: <Image src={mediaImage} avatar circular />,
            content: `Total: ${Total || 0}, Draft: ${Draft || 0}, Awaiting: ${
              statusMap?.["Awaiting response"] || 0
            }, Inprogress: ${inprogress || 0}, Completed: ${Completed || 0}`,
            headerMedia: moment(createdAt).format("Do MMM YYYY"),
          });
        });
      }
      setItems(items);
      setFilterList(items);
    } catch (error) {
      logger.error("Error while fetching assessment", error);
    }
    stopLoading();
  };

  const onSelectedIndexChange = (a, newProps) => {
    const { items, selectedIndex } = newProps || {};
    const selectedAssessment = items[selectedIndex];
    const { key } = selectedAssessment || {};

    setSelectedIndex(selectedIndex);
    selectedAssessmentId.current = key;
  };

  useEffect(() => {
    if (selectedIndex >= 0) {
      MicrosoftTeams.settings.setValidityState(true);
    }
  }, [selectedIndex]);

  useEffect(() => {
    fetchAssessments();

    MicrosoftTeams.settings.registerOnSaveHandler(async (saveEvent) => {
      const selectedAssessmentIds = [selectedAssessmentId.current];
      const origin = window.location.origin;
      const context = await getTeamsContext();
      const { teamId, groupId, channelId, theme } = context || {};
      const entityId = `ctab-${uuid()}`;
      const assessmentIds = selectedAssessmentIds.join("-");
      const queryString = `vig=${assessmentIds}&teamId=${teamId}&channelId=${channelId}&groupId=${groupId}&theme=${theme}`;
      const sdata = {
        context,
        teamId,
        groupId,
        channelId,
        entityId,
        assessmentIds,
      };
      saveTabContext(sdata)
        .then((res) => {
          const { error, records } = res || {};
          if (!error && records) {
            MicrosoftTeams.settings.setSettings({
              contentUrl: `${origin}/ctab/${assessmentIds}/${entityId}/?${queryString}`,
              suggestedDisplayName: "X0PA Room",
              removeUrl: `${origin}/ctab/${assessmentIds}/${entityId}/r/?${queryString}`,
              entityId: entityId,
            });
            saveEvent.notifySuccess();
          } else {
            logger.error("ERROR", res);
            saveEvent.notifyFailure();
          }
        })
        .catch(() => {
          saveEvent.notifyFailure();
        });
    });
  }, []);

  const openBotChat = useCallback(() => {
    MicrosoftTeams.executeDeepLink(
      "https://teams.microsoft.com/l/chat/0/0?users=28:d304c4b4-8353-463f-910d-3819e3c214bd"
    );
  }, []);

  const emptyList = () => {
    return (
      <Communication
        option={CommunicationOptions.Empty}
        fields={{
          title: t("noAssessmentTitle"),
          desc: t("noAssessmentDesc"),
          actions: {
            primary: {
              label: t("createAssessment"),
              target: "create-assessment",
            },
          },
        }}
        onInteraction={(interaction) => {
          if (interaction?.target === "create-assessment") {
            openBotChat();
          }
        }}
      />
    );
  };

  const onSearchTextChange = useCallback(() => {
    const searchText = document.getElementById(searchTextInputId).value?.toLowerCase();
    const filter = searchText
      ? items.filter((i) => i.groupName.toLowerCase().includes(searchText))
      : items;
    setFilterList(filter);
  }, [items]);

  if (isLoading) return <FullScreenLoader label={commonT("loading")} />;
  return (
    <div
      style={{
        background: theme.siteVariables.bodyBackground,
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      {items.length === 0 ? (
        emptyList()
      ) : (
        <>
          <Flex column>
            <Text size="large" weight="semibold" className="mb-2">
              {t("title")}
            </Text>
            <Input
              className="mb-2"
              fluid
              icon={<SearchIcon />}
              placeholder="Search..."
              id={searchTextInputId}
              onChange={onSearchTextChange}
            />
          </Flex>
          <Flex column style={{ overflowY: "scroll", overflowX: "hidden", height: "82%" }}>
            <List
              selectable
              items={filterList.map((item, index) => {
                if (selectedIndex === index) {
                  item.styles = ({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background4,
                  });
                } else {
                  item.styles = ({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background,
                  });
                }
                return item;
              })}
              onSelectedIndexChange={onSelectedIndexChange}
            />
          </Flex>
        </>
      )}
    </div>
  );
};

export default ChannelTabConfig;
