import React, { useEffect } from "react";
import { Table } from "@fluentui/react-teams";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { questionActions, questionSelector as selector } from "../../store/slices/questionSlice";
import openQuestionCard from "../../adaptiveCards/QuestionCard/openQuestionCard";
import useSnackbar from "../../hooks/useSnackbar";
import useLoader from "../../hooks/useLoader";
import questionAPI from "../../api/questionAPI";
import useDialog from "../../hooks/useDialog";
import logger from "../../services/logger";

const QuestionList = ({ assessment, questionCriteria }) => {
  const questions = useSelector(selector.questions(assessment.videointerviewGroupId));
  const { getUserConfirmation, Dialog, USER_REJECTED_ERROR } = useDialog();
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const fetchQuestions = async (assessmentId) => {
    try {
      startLoading("Loading Questions...");
      const { hybridQuestions } = await questionAPI.getAssessmentQuestions(assessmentId);
      dispatch(questionActions.setQuestions(hybridQuestions));
      stopLoading();
    } catch (error) {
      stopLoading();
      snackbar("Error while loading questions.", "ERROR");
      logger.error("Error: while fetching questions ", error);
    }
  };

  useEffect(() => {
    fetchQuestions(assessment.videointerviewGroupId);
  }, []);

  const editQuestion = async (hybridQuestionId) => {
    try {
      const hybridQuestion = questions.find((q) => q.hybridQuestionId === hybridQuestionId);
      const result = await openQuestionCard(
        {
          assessmentName: assessment.groupName,
          assessmentId: assessment.videointerviewGroupId,
          questionCriteria,
          questionCategory: assessment?.groupConfig?.groupCategory || [],
          questionName: hybridQuestion.hybridQuestionName,
          questionType: hybridQuestion.hybridQuestionType,
          categories: hybridQuestion.category.map((c) => c.hybridQuestionCategoryName),
          criterias: hybridQuestion.criteria.map((c) => c.criteriaId + ""),
          prepTime: hybridQuestion.hybridPrepTime,
          maxAnswerTime: hybridQuestion.hybridMaxAnswerTime,
          maxRetakeTime: hybridQuestion.hybridMaxRetakeTime,
          retakeDecisionTime: hybridQuestion.hybridRetakeDecisionTime,
          maxWords: +hybridQuestion.hybridMaxWords,
          minWords: +hybridQuestion.hybridMinWords,
        },
        "EDIT"
      );
      startLoading("Updating Question...");
      await questionAPI.updateQuestion({
        ...result,
        hybridQuestionId: hybridQuestion.hybridQuestionId,
      });
      fetchQuestions(assessment.videointerviewGroupId);
      snackbar("Question updated successfully", "SUCCESS");
    } catch (err) {
      if (err?.code) {
        // API error
        snackbar("Fail to edit Notifier", "ERROR");
      }
      logger.error(err);
    }
    stopLoading();
  };

  const deleteQuestion = async (hybridQuestionId) => {
    try {
      await getUserConfirmation({
        header: "Delete Question",
        content: "Are you sure you want to delete this question?",
      });
      startLoading("Deleting Question");
      await questionAPI.deleteQuestion(hybridQuestionId);
      dispatch(questionActions.removeQuestions(hybridQuestionId));
    } catch (error) {
      stopLoading();
      if (error?.code === USER_REJECTED_ERROR) {
        return;
      }
      logger.error("Error while deleting candidates");
      snackbar("Fail to delete the candidate", "ERROR");
    }
    stopLoading();
  };

  const handleToolbarInteraction = (e) => {
    if (e.action === "edit") {
      editQuestion(e.subject);
    } else if (e.action === "close") {
      deleteQuestion(e.subject);
    }
  };

  const getListRow = (ques) => {
    const criteriaIds = ques.criteria.map((q) => +q.criteriaId);
    // mapping category id to its name
    const categoryString = questionCriteria
      .filter((c) => criteriaIds.includes(c.videointerviewgroupCriteriaId))
      .map((c) => c.criteriaName)
      .join(", ");

    // converting the number to string because list support only string value
    const rowData = {
      qText: {
        content: (
          <Text weight="semibold">
            <div dangerouslySetInnerHTML={{ __html: ques.hybridQuestionName }} />
          </Text>
        ),
      },
      qType: ques.hybridQuestionType,
      qCategory: ques.category?.[0].hybridQuestionCategoryName || "-",
      ratingCriteria: categoryString,
      prepTime: (ques.hybridPrepTime+"").toString(),
      maxAnswerTime: (ques.hybridMaxAnswerTime+"").toString(),
      actions: {
        edit: { title: "Edit Question", icon: "Edit" },
        close: { title: "Remove Question", icon: "TrashCan" },
      },
    };
    return rowData;
  };

  const tableProps = {
    onInteraction: (e) => {
      handleToolbarInteraction(e);
    },

    columns: {
      qText: {
        title: "Question",
      },
      qType: {
        title: "Type",
        minWidth: 100,
      },
      qCategory: {
        title: "Category",
        minWidth: 100,
      },
      ratingCriteria: {
        title: "Rating Criteria",
        minWidth: 150,
      },
      prepTime: {
        title: "Prep Time",
        minWidth: 100,
      },
      maxAnswerTime: {
        title: "Max Answer Time",
        minWidth: 100,
      },
    },
    rows: questions.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.hybridQuestionId]: getListRow(cur),
      }),
      {}
    ),
  };

  if (isLoading) {
    return <Loader label={loadingMessage} />;
  }

  return (
    <div>
      <Flex column vAlign="start">
        <Text weight="semibold" className="pb-3">
          Questions
        </Text>
      </Flex>
      <div>
        <Table {...tableProps} />
      </div>
      <Dialog />
    </div>
  );
};

export default QuestionList;
