import { Dialog as DialogComponent } from "@fluentui/react-northstar";
import React from "react";
import { useState } from "react";

type TDialog = {
  header?: string;
  content?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCancel?: (event: any) => any;
  onConfirm?: () => any;
};

function useDialog() {
  const [dialog, setDialog] = useState<TDialog>();
  const [isOpen, setIsOpen] = useState(false);
  const USER_REJECTED_ERROR = 1000;

  const getUserConfirmation = async ({
    header,
    content,
    cancelButtonLabel = "Cancel",
    confirmButtonLabel = "Confirm",
  }: {
    header: string;
    content: string;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
  }) => {
    return new Promise<true>((resolve, reject) => {
      setIsOpen(true);
      setDialog({
        header,
        content,
        cancelButtonLabel,
        confirmButtonLabel,
        onCancel: (event: any) => {
          reject({ code: USER_REJECTED_ERROR, event });
          setIsOpen(false);
        },
        onConfirm: () => {
          resolve(true);
          setIsOpen(false);
        },
      });
    });
  };

  const alertUser = async ({
    header,
    content,
    buttonLabel,
  }: {
    header: string;
    content: string;
    buttonLabel?: string;
  }) => {
    return new Promise<true>((resolve, reject) => {
      setIsOpen(true);
      setDialog({
        header,
        content,
        confirmButtonLabel: buttonLabel || "Continue",
        onConfirm: () => {
          resolve(true);
          setIsOpen(false);
        },
      });
    });
  };

  const Dialog = () => (
    <DialogComponent
      cancelButton={dialog?.cancelButtonLabel}
      confirmButton={dialog?.confirmButtonLabel}
      onCancel={dialog?.onCancel}
      onConfirm={dialog?.onConfirm}
      content={dialog?.content}
      header={dialog?.header}
      open={isOpen}
    />
  );

  return {
    Dialog,
    alertUser,
    getUserConfirmation,
    USER_REJECTED_ERROR,
  };
}

export default useDialog;
