import { xfetch } from "../services/xfetch";
import { BaseApiPath } from "../utils/constants";

const getUserId = async () => {
  const xpath = `${BaseApiPath}/userinfos/a/i`;
  return xfetch(xpath, "get", null);
};

const userAPI = {
  getUserId,
};

export default userAPI;
