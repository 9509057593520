import { useContext, useEffect, useState } from "react";
import { Client } from "@microsoft/microsoft-graph-client";
import GraphContext from "./GraphContext";

const checkAllRoleSatisfy = (roles: string[], requiredRoles: string[]) => {
  if (!roles) return false;
  return requiredRoles.every((e) => roles.includes(e));
};

function useGraphClient() {
  const { graphToken, applicationLevelToken } = useContext(GraphContext);
  const [client, setClient] = useState<Client | undefined>(undefined);

  useEffect(() => {
    if (graphToken) {
      // if (graphToken.expireDate <= new Date()) {
      //   // token is expire
      //   resetGraphToken();
      // }
      const client = Client.init({
        authProvider: (done: any) => {
          done(null, graphToken.accessToken);
        },
      });
      setClient(client);
    }
  }, [graphToken]);

  const getApplicationLevelClient = (roles: string[]) => {
    if (
      applicationLevelToken &&
      applicationLevelToken.roles &&
      checkAllRoleSatisfy(applicationLevelToken?.roles, roles)
    ) {
      // if (applicationLevelToken.expireDate <= new Date()) {
      //   // token is expire
      //   resetGraphToken();
      // }
      const client = Client.init({
        authProvider: (done: any) => {
          done(null, applicationLevelToken.accessToken);
        },
      });
      return client;
    }
    return undefined;
  };

  return { client, getApplicationLevelClient };
}

export default useGraphClient;
