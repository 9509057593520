import { BaseApiPath, ErrorCode } from "../utils/constants";
import { xfetch } from "../services/xfetch";

const videoInterviewPath = `${BaseApiPath}/videointerviewgroups`;

// TODO: add limit and skip
const getAssessmentCandidates = async (assessmentId: number) => {
  // 737?_=1647409657518&limit=10&skip=0&
  const xpath = `${videoInterviewPath}/videointerview/${assessmentId}`;
  const response = await xfetch(xpath, "get", null);
  return response.Videointerviews;
};

const addAssessmentCandidates = async (assessmentId: number, candidate: any) => {
  const xpath = `${videoInterviewPath}/profile/${assessmentId}`;
  const data = {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    emailAddress: candidate.email,
    videointerviewGroupId: assessmentId,
  };
  return await xfetch(xpath, "post", data);
};

const sendInviteToCandidate = async (interviewIds: string[], assessmentId: number) => {
  const xpath = `${videoInterviewPath}/invite`;
  const data = {
    interviewIds: interviewIds,
    videointerviewGroupId: assessmentId,
  };
  const response = await xfetch(xpath, "post", data);
  if (response.error) {
    const error = { code: ErrorCode.API_ERROR, message: response.error.message };
    throw error;
  }
};

const deleteAssessmentCandidates = async (candidates: any[]) => {
  const xpath = `${videoInterviewPath}/profiles`;
  const data = candidates.map((c) => ({
    profileId: c.profileId,
    videointerviewGroupId: c.videointerviewGroupId,
  }));
  return await xfetch(xpath, "delete", data);
};

const editAssessmentCandidates = async (candidate: any, assessmentId: number) => {
  const xpath = `${videoInterviewPath}/profile/${assessmentId}`;
  const data = {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    emailAddress: candidate.email,
    profileId: candidate.profileId,
    videointerviewGroupId: assessmentId,
  };
  return await xfetch(xpath, "patch", data);
};

const candidateAPI = {
  getAssessmentCandidates,
  addAssessmentCandidates,
  sendInviteToCandidate,
  deleteAssessmentCandidates,
  editAssessmentCandidates,
};

export default candidateAPI;
