import React from "react";
import { Link } from "@fluentui/react";
import { Trans, useTranslation } from "react-i18next";
import { Text, Button, Flex } from "@fluentui/react-northstar";

export default function Welcome({ authButtonMethod }: { authButtonMethod: () => void }) {
  const { t } = useTranslation("translation", { keyPrefix: "welcomeScreen" });

  return (
    <Flex column padding="padding.medium">
      <Text>
        <Trans t={t} i18nKey="intro" components={{ b: <b /> }} />
      </Text>
      <Text>
        <Button style={{ marginTop: 10 }} onClick={authButtonMethod}>
          {t("signIn")}
        </Button>
      </Text>
      <Text style={{ marginTop: 10 }}>
        <Trans
          t={t}
          i18nKey="signUp"
          components={{
            Link: (
              <Link
                target="_blank"
                href="https://room.x0pa.ai/public/room/onboard?ref=xteams-channel-tab"
              />
            ),
          }}
        />
      </Text>
    </Flex>
  );
}
