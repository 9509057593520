import renderAdaptiveCard from "../../utils/renderAdaptiveCard";
import { startTeamsTask } from "../../utils/teamsTasks";

import candidateCard from "./CandidateCard.json";

type Candidate = {
  assessmentId: string;
  assessmentName: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

const openCandidateCard = async (candidate: Candidate, mode: "ADD" | "EDIT" = "ADD") => {
  const result = await startTeamsTask({
    title: mode === "ADD" ? " Add New Candidate" : "Edit Candidate Detail",
     card: renderAdaptiveCard(candidateCard, {
       ...candidate,
       action: mode === "ADD" ? " ADD" : "EDIT",
     }),
  });

  return result;
};

export default openCandidateCard;
