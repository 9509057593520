import { InProduction } from "../Config";

const __no_op = function () {};

const logger = {
  info: window.console.info.bind(window.console, "INFO: %s"),
  debug: InProduction ? __no_op : window.console.debug.bind(window.console, "DEBUG: %s"),
  error: window.console.error.bind(window.console, "ERROR: %s"),
};

export default logger;
