import * as stjs from "stjs";

// Refer from teams sample code
const renderAdaptiveCard = (template, data) => {
  // Pre-process the template so that template placeholders don't show up for null data values
  // Regex: Find everything between {{}} and prepend "#? " to it
  template = JSON.parse(JSON.stringify(template).replace(/{{(.+?)}}/g, "{{#? $1}}"));

  // No error handling in the call to stjs functions - what you pass in may be garbage, but it always returns a value
  let ac = stjs.select(data).transformWith(template).root();
  return {
    contentType: "application/vnd.microsoft.card.adaptive",
    content: ac,
  };
};

export default renderAdaptiveCard;
