import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Candidate = {
  assessmentId: string,
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  status: string,
  result: string,
  decision: string,
  overallScore: string,
}  

const initialState: {candidates: Candidate[]} = {
  candidates: [],
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setCandidates(state, action) {
      state.candidates = action.payload;
    },
    addCandidate({candidates}, action) {
      candidates.push({...action.payload, id: `${5001 + candidates.length }`})
    },
    editCandidate({ candidates }, action) {
      const id = candidates.findIndex(q => q.id === action.payload.id); 
      candidates[id] = {...candidates[id], ...action.payload};
    },
    removeCandidates(state, action) {
      state.candidates = state.candidates.filter(q => !(action.payload.includes(q.id)))
      return state
    },
  },
});

export default candidateSlice.reducer;

export const candidateActions = { ...candidateSlice.actions };

export const candidateSelector = {
  candidates: (assessmentId: string) => (store: RootState) => store.candidate.candidates.filter((q) => q.assessmentId === assessmentId),
};
