import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@fluentui/react-northstar";

const PageNotFound = () => {
  const { t } = useTranslation("translation", { keyPrefix: "pageNotFound" });

  return (
    <Flex className="pt-4" hAlign="center">
      <Text size="large" weight="semibold">
        {t("pageNotFoundMessage")}
      </Text>
    </Flex>
  );
};

export default PageNotFound;
