import React, { useEffect } from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { useQuery, useQueryClient } from "react-query";

import utils from "../../utils/utils";
import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import useDialog from "../../hooks/useDialog";
import notifierAPI from "../../api/notifierAPI";
import useSnackbar from "../../hooks/useSnackbar";
import { Assessment } from "../../types/Assessment";
import CustomList, { TCustomList } from "../../components/CustomList";
import openNotifierCard from "../../adaptiveCards/NotifierCard/openNotifierCard";

type Props = {
  assessment: Assessment;
};

const NOTIFIER_QUERY_KEY = "Notifier";

function NotificationsTab({ assessment }: Props) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { getUserConfirmation, Dialog, USER_REJECTED_ERROR } = useDialog();
  const {
    data: notifiers,
    error,
    isLoading: listLoader,
  } = useQuery([NOTIFIER_QUERY_KEY, assessment.videointerviewGroupId], () =>
    notifierAPI.getAssessmentNotifiers(assessment.videointerviewGroupId)
  );

  const { startLoading, stopLoading, isLoading, loadingMessage } = useLoader();

  useEffect(() => {
    if (error && snackbar) {
      logger.error("Error while fetching Notifiers", error);
      snackbar("Failure to load Notifires: try again", "ERROR", "LONG");
    }
  }, [error, snackbar]);

  const addNotifier = async () => {
    try {
      const { name, email } = await openNotifierCard({
        assessmentId: assessment.videointerviewGroupId + "",
        assessmentName: assessment.groupName,
      });

      startLoading("Adding...");
      await notifierAPI.addAssessmentNotifier(name, email, assessment.videointerviewGroupId + "");

      // refetch the list
      await queryClient.refetchQueries([NOTIFIER_QUERY_KEY, assessment.videointerviewGroupId]);
      stopLoading();
      snackbar("Notifier added successfully", "SUCCESS");
    } catch (error) {
      if ((error as any)?.code) {
        // API error
        snackbar("Fail to add Notifier", "ERROR");
      }
      logger.error("Error while adding Notifier", error);
      stopLoading();
    }
  };

  const editNotifier = async (id: string) => {
    try {
      const notifier = notifiers?.find((c) => +c.notificationId === +id);

      const { name, email } = await openNotifierCard(
        {
          assessmentId: assessment.videointerviewGroupId + "",
          assessmentName: assessment.groupName,
          email: notifier?.email,
          name: notifier?.name,
        },
        "EDIT"
      );

      startLoading("Updating...");
      await notifierAPI.updateAssessmentNotifier(
        notifier?.notificationId + "",
        name,
        email,
        assessment.videointerviewGroupId + ""
      );

      // refetch the list
      await queryClient.refetchQueries([NOTIFIER_QUERY_KEY, assessment.videointerviewGroupId]);
      stopLoading();
    } catch (error) {
      if ((error as any)?.code) {
        // API error
        snackbar("Fail to edit Notifier", "ERROR");
      }
      logger.error("Error while editing Notifier", error);
      stopLoading();
    }
  };

  const removeNotifier = async (id: string) => {
    try {
      await getUserConfirmation({
        header: "Delete Notifier",
        content: "Are you sure you want to delete the selected notifier?",
      });
      startLoading("Deleting...");
      await notifierAPI.deleteAssessmentNotifier(id);

      // refetch the list
      await queryClient.refetchQueries([NOTIFIER_QUERY_KEY, assessment.videointerviewGroupId]);
    } catch (error) {
      if ((error as any)?.code === USER_REJECTED_ERROR) {
        return;
      }
      snackbar("Fail to delete Notifier", "ERROR");
      logger.error("Error while deleting Notifier", error);
    }
    stopLoading();
  };

  const getListRow = ({ name, email }: { name: string; email: string }) => {
    return {
      name: {
        content: (
          <Text weight="bold" color="brand">
            {name}
          </Text>
        ),
      },
      email,
    };
  };

  const listProps: TCustomList = {
    loading: listLoader || isLoading,
    loadingMessage: loadingMessage || "Loading...",
    items: notifiers,
    itemIdKeyName: "notificationId",
    toolbarActions: {
      addNotifier: {
        title: "Add Notifier",
        icon: "Add",
        onClick: addNotifier,
      },
    },
    columns: {
      name: {
        title: "Name",
      },
      email: {
        title: "Email",
      },
    },
    renderRow: getListRow,
    rowActions: {
      edit: { title: "Edit", icon: "Edit", onClick: editNotifier },
      delete: { title: "Delete", icon: "TrashCan", onClick: removeNotifier },
    },
    findComparator: ({ name, email }, findString) => {
      return utils.checkStringIncludeInTexts(findString, [name, email]);
    },
  };

  return (
    <Flex column className="pt-4">
      <Text
        className="pb-3"
        content="Notifiers receive email notification when a candidate completes the assessment."
        weight="semilight"
        size="small"
      />
      <Button text primary>Add Notifier</Button>
      <CustomList {...listProps} />
      <Dialog />
    </Flex>
  );
}

export default NotificationsTab;
