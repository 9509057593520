import renderAdaptiveCard from "../../utils/renderAdaptiveCard";
import { startTeamsTask } from "../../utils/teamsTasks";

import questionStep1Card from "./QuestionStep1.json";
import questionStep1CardVideo from "./QuestionStep2Video.json";
import questionStep1CardText from "./QuestionStep2Text.json";

const addCardSelectorValues = (card: any, category: any[], criteria: any[]) => {
  card.body[1].items[4].columns[0].items[1].choices = category?.map((value: any) => ({
    title: value,
    value: value,
  }));

  card.body[1].items[4].columns[1].items[1].choices = criteria?.map((c: any) => ({
    title: c.criteriaName,
    value: c.videointerviewgroupCriteriaId + "",
  }));
};

interface Question {
  questionName?: string;
  questionType?: string;
  categories?: string[];
  criterias?: string[];
  prepTime?: number;
  maxAnswerTime?: number;
  minWords?: number;
  maxWords?: number;
  maxRetakeTime?: number;
  retakeDecisionTime?: number;
  assessmentName: string;
  assessmentId: number;
  questionCategory: any[];
  questionCriteria: any[];
}

const openQuestionCard = async (question: Question, mode: "ADD" | "EDIT" = "ADD") => {
  const questionCardData = {
    questionType: "video", // setting default value
    ...question,
    // TODO: support multiple value value
    categories: question?.categories?.[0],
    criterias: question?.criterias?.[0],
    title: `${question.assessmentName} (${question.assessmentId})`,
  };

  // adding criteria in card
  addCardSelectorValues(questionStep1Card, question.questionCategory, question.questionCriteria);

  const result1 = await startTeamsTask({
    title: `${mode === "ADD" ? "Add" : "Edit "} Question - Step 1`,
    card: renderAdaptiveCard(questionStep1Card, questionCardData),
  });

  const result2 = await startTeamsTask({
    title: `${mode === "ADD" ? "Add" : "Edit "} Question - Step 2`,
    card: renderAdaptiveCard(
      result1.questionType === "video" ? questionStep1CardVideo : questionStep1CardText,
      questionCardData
    ),
  });

  return {
    ...result1,
    ...result2,
    categories: [result1.categories],
    criterias: [result1.criterias],
  };
};

/**
 * Return Type
categories: ['testcat1']
criterias: ['2']
maxAnswerTime: "120"
maxRetakeTime: "2"
prepTime: "120"
questionName: "question formteams"
questionType: "video"
retakeDecisionTime: "120"
xaction: "submit-question-step2"
 }
 */

export default openQuestionCard;
