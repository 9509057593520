import { Assessment } from "../types/Assessment";
import { xfetch } from "../services/xfetch";
import { BaseApiPath } from "../utils/constants";
const basePath = `${BaseApiPath}/videointerviewgroups`;

const listAssessments = async () => {
  const xpath = `${basePath}/company`;
  const response = await xfetch(xpath, "get", null);
  if (response?.error) {
    throw new Error(response.error);
  }
  return response;
};

const getAssessmentById = async (id: string | number): Promise<Assessment> => {
  const xpath = `${basePath}/${id}`;
  return xfetch(xpath, "get", null);
};

interface AddAssessmentParam {
  groupName: string;
  landingPageInfo: string;
  startsAt: string;
  expiresAt: string;
  expirationDate: string;
  groupConfig: {
    interviewType: string;
  };
  enableReminder: boolean;
  reminderInterval?: any;
  maxReminders?: any;
  responseVisibility: boolean;
  enableBanner: boolean;
  enableInclusiveHiring: boolean;
}

const addAssessment = async (data: AddAssessmentParam) => {
  return xfetch(basePath, "post", data);
};

const editAssessment = async (videointerviewGroupId: number, data: any) => {
  const xpath = `${basePath}/${videointerviewGroupId}`;
  const response = await xfetch(xpath, "patch", data);
  if (typeof response.groupConfig === "string")
    response.groupConfig = JSON.parse(response?.groupConfig);
  return response;
};

const closeAssessment = async (
  videointerviewGroupId: number,
  data: { expirationDate: string; expiresAt: string }
) => {
  const xpath = `${basePath}/${videointerviewGroupId}`;
  return xfetch(xpath, "patch", data);
};

const assessmentAPI = {
  listAssessments,
  addAssessment,
  getAssessmentById,
  editAssessment,
  closeAssessment,
};

export default assessmentAPI;
