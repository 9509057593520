import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Flex, Card, Text, Grid } from "@fluentui/react-northstar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { calculatePercentage } from "../../utils/math";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { StatusMap } from "../../types/Assessment";

type RenderCardType = {
  label: string;
  value: number | string;
  total: number | string;
};
const RenderCard = ({ label, value, total }: RenderCardType) => {
  const percentage = calculatePercentage(value, total);
  return (
    <Flex className="m-1">
      <Card elevated size="small" fluid>
        <Flex vAlign="center" space="between" style={{ padding: "8px 16px", height: "80px" }}>
          <Flex column vAlign="center">
            <Text weight="semibold">{label}</Text>
            <Text weight="bold" size="larger">
              {value}
            </Text>
          </Flex>
          <div style={{ width: 30, height: 30 }}>
            <CircularProgressbar
              value={percentage}
              strokeWidth={20}
              background={true}
              styles={buildStyles({
                pathColor: `rgba(34, 139, 34)`,
                backgroundColor: "transparent",
              })}
            />
          </div>
        </Flex>
      </Card>
    </Flex>
  );
};

type Props = {
  status?: StatusMap;
  className?: string;
};

const MetricCard = ({ status, className }: Props) => {
  const { width } = useWindowDimensions();

  const {
    "Awaiting response": awaitingResponse,
    Completed,
    Draft,
    Total,
    inprogress,
  } = {
    "Awaiting response": 0,
    Completed: 0,
    Draft: 0,
    Total: 0,
    inprogress: 0,
    ...status,
  };

  const calculateColumn = () => {
    const result = width / 250;
    return result <= 5 ? Math.round(result) : 5;
  };
  return (
    <Grid
      className={className}
      content={[
        <RenderCard label="Total" total={Total} value={Total} key="MetrixCard-1" />,
        <RenderCard
          label="Awaiting Response"
          value={awaitingResponse}
          total={Total}
          key="MetrixCard-2"
        />,
        <RenderCard label="Completed" value={Completed} total={Total} key="MetrixCard-3" />,
        <RenderCard label="Draft" value={Draft} total={Total} key="MetrixCard-4" />,
        <RenderCard label="In Progress" value={inprogress} total={Total} key="MetrixCard-5" />,
      ]}
      columns={calculateColumn()}
    />
  );
};

export default MetricCard;
