import React from "react";
import { FC, useEffect, useState } from "react";
import { Provider } from "@fluentui/react-teams";
import * as microsoftTeams from "@microsoft/teams-js";
import { getTeamsContext } from "../utils/teamsUtils";
import { IThemeTeamsClient } from "@fluentui/react-teams/lib/cjs/lib/withTheme";

const ThemeProvider: FC = ({ children }) => {
  const [themeName, setThemeName] = useState<IThemeTeamsClient>(IThemeTeamsClient.Default);

  const themeChangeHandler = (themeMode: any) => {
    setThemeName(themeMode);
  };

  const setThemeAndRegisterThemeHandler = async () => {
    const context = await getTeamsContext();
    themeChangeHandler(context.theme);
    microsoftTeams.registerOnThemeChangeHandler(themeChangeHandler);
  };

  useEffect(() => {
    setThemeAndRegisterThemeHandler();
  }, []);

  return (
    <Provider themeName={themeName} lang="en-US">
      <div style={{ background: "transparent", minHeight: "100vh" }}>{children}</div>
    </Provider>
  );
};

export default ThemeProvider;
