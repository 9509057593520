import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { Flex, Text } from "@fluentui/react-northstar";

import utils from "../../utils/utils";
import logger from "../../services/logger";
import useDialog from "../../hooks/useDialog";
import useLoader from "../../hooks/useLoader";
import candidateAPI from "../../api/candidateAPI";
import useSnackbar from "../../hooks/useSnackbar";
import { Assessment } from "../../types/Assessment";
import { useQuery, useQueryClient } from "react-query";
import { ASSESSMENT_QUERY_KEY } from "./AssessmentDetails";
import CustomList, { TCustomList, TRowActions } from "../../components/CustomList";
import openCandidateCard from "../../adaptiveCards/CandidateCard/openCandidateCard";
import { questionSelector } from "../../store/slices/questionSlice";

type Props = {
  assessment: Assessment;
  showCandidateDetailScreen?: (id: string) => void;
};

function CandidatesTab({ assessment, showCandidateDetailScreen }: Props) {
  const snackbar = useSnackbar();
  const { getUserConfirmation, Dialog, USER_REJECTED_ERROR, alertUser } = useDialog();
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader();
  const questions = useSelector(questionSelector.questions(assessment?.videointerviewGroupId + ""));

  const queryClient = useQueryClient();
  const {
    data: candidates,
    error,
    isLoading: listLoading,
  } = useQuery(["candidates", assessment.videointerviewGroupId], () =>
    candidateAPI.getAssessmentCandidates(assessment.videointerviewGroupId)
  );

  useEffect(() => {
    if (error && snackbar) {
      logger.error("Error while fetching candidates ", error);
      snackbar("Failure to load the candidate: re-try", "ERROR", "LONG");
    }
  }, [error, snackbar]);

  const addCandidate = async () => {
    try {
      const result = await openCandidateCard(
        {
          assessmentId: assessment.videointerviewGroupId + "",
          assessmentName: assessment.groupName,
        },
        "ADD"
      );
      startLoading("Adding...");
      await candidateAPI.addAssessmentCandidates(assessment.videointerviewGroupId, result);
      await queryClient.refetchQueries(["candidates", assessment.videointerviewGroupId]);
      await queryClient.refetchQueries([ASSESSMENT_QUERY_KEY, assessment.videointerviewGroupId]);
      snackbar("Candidate added successfully", "SUCCESS");
    } catch (e) {
      const error = e as { code?: string; message?: string };

      if ((error as any)?.code) {
        if (error?.message === '"Duplicate Key found"') {
          snackbar("Duplicate email id found", "ERROR");
        } else snackbar("Fail to add Candidate", "ERROR");
      }
      logger.error("Error while adding Candidate", error);
    }
    stopLoading();
  };

  const editCandidate = async (interviewId: any) => {
    try {
      const candidate = candidates.find((c: any) => c.interviewId === interviewId);
      const result = await openCandidateCard(
        {
          assessmentId: assessment.videointerviewGroupId + "",
          assessmentName: assessment.groupName,
          firstName: candidate.profile.firstName,
          lastName: candidate.profile.lastName,
          email: candidate.profile.email,
        },
        "EDIT"
      );
      startLoading("Updating...");
      await candidateAPI.editAssessmentCandidates(
        { ...result, profileId: candidate.profileId },
        assessment.videointerviewGroupId
      );
      await queryClient.refetchQueries(["candidates", assessment.videointerviewGroupId]);
      snackbar("Candidate updated successfully", "SUCCESS");
    } catch (error) {
      if ((error as any)?.code) snackbar("Fail to update", "ERROR");
      logger.error("Error while updating candidate info", error);
    }
    stopLoading();
  };

  const removeCandidate = async (interviewIds: string[]) => {
    try {
      await getUserConfirmation({
        header: "Delete Candidate",
        content: "Are you sure you want to delete the selected candidate?",
      });
      startLoading("Deleting...");
      const removeCandidates = candidates.filter((c: any) => interviewIds.includes(c.interviewId));
      await candidateAPI.deleteAssessmentCandidates(removeCandidates);
      await queryClient.refetchQueries(["candidates", assessment.videointerviewGroupId]);
      await queryClient.refetchQueries([ASSESSMENT_QUERY_KEY, assessment.videointerviewGroupId]);
      stopLoading();
      snackbar("Candidate deleted successfully", "SUCCESS");
    } catch (error) {
      stopLoading();
      if ((error as any)?.code === USER_REJECTED_ERROR) {
        return;
      }
      logger.error("Error while deleting candidates");
      snackbar("Fail to delete the candidate", "ERROR");
    }
  };

  const sendInvite = async (interviewIds: any) => {
    try {
      logger.info("Question length: ", questions.length);
      if (questions.length === 0) {
        try {
          await alertUser({
            header: "No question found",
            content: "Add questions before sending an invite to the candidate.",
            buttonLabel: "Ok",
          });
        } catch (e) {}
        return;
      }
      await getUserConfirmation({
        header: "Invite Candidate",
        content: "Are you sure you want to invite this candidate for interview?",
      });
      startLoading("Sending Invitation...");
      await candidateAPI.sendInviteToCandidate(interviewIds, assessment.videointerviewGroupId);
      await queryClient.refetchQueries(["candidates", assessment.videointerviewGroupId]);
      await queryClient.refetchQueries([ASSESSMENT_QUERY_KEY, assessment.videointerviewGroupId]);
      stopLoading();
      snackbar("Invite send successfully", "SUCCESS");
    } catch (error) {
      stopLoading();
      if ((error as any)?.code === USER_REJECTED_ERROR) {
        return;
      }
      logger.error("Error while sending invites ", error);
      snackbar("Fail to send invites", "ERROR");
    }
  };

  const handleClickAction = (e: any, candidate: any) => {
    e.stopPropagation();
    e.preventDefault();
    const cstatus = candidate.status;
    const lstatus = (cstatus + "").toLowerCase().trim();
    if (["inprogress", "completed"].includes(lstatus))
      showCandidateDetailScreen?.(candidate.interviewId);
  };

  const getListRow = (candidate: any) => {
    const cstatus = candidate.status;
    const lstatus = (cstatus + "").toLowerCase().trim();
    const isRowClickable = ["inprogress", "completed"].includes(lstatus);
    return {
      id: candidate.id,
      name: {
        content: (
          <Flex
            column
            onClick={(e: any) => isRowClickable && handleClickAction(e, candidate)}
            className={isRowClickable ? "cursor-pointer" : "cursor-auto"}
          >
            <Text
              weight="bold"
              color="brand"
            >{`${candidate.profile.firstName} ${candidate.profile.lastName}`}</Text>
            <Text weight="light" size="small">
              {candidate.profile.email}
            </Text>
          </Flex>
        ),
      },
      status:
        candidate.status === "inprogress"
          ? "In Progress"
          : utils.convertToTitleCase(candidate.status),
      result: utils.convertToTitleCase(candidate.result) || "No Result",
      decision: {
        content: <Text>{utils.convertToTitleCase(candidate.outcome) || "Pending"}</Text>,
      },
      overallScore: {
        content: (
          <StarRatings
            rating={candidate.totalScore || 0}
            starRatedColor="gold"
            numberOfStars={5}
            name="rating"
            starDimension="24px"
            starSpacing="0px"
          />
        ),
      },
    };
  };

  const rowActions: TRowActions = {
    edit: { title: "Edit", icon: "Edit", onClick: (id: any) => editCandidate(id) },
    sendInvite: { title: "Send Invite", icon: "Send", onClick: (id: any) => sendInvite(id) },
    remove: {
      title: "Remove",
      icon: "TrashCan",
      onClick: (id: any) => removeCandidate([id]),
    },
  };

  const listProps: TCustomList = {
    loading: listLoading || isLoading,
    loadingMessage: loadingMessage || "Loading...",
    items: candidates,
    itemIdKeyName: "interviewId",
    toolbarActions: {},
    columns: {
      id: {
        title: "ID",
        minWidth: 50,
      },
      name: {
        title: "Candidate Name",
        textSelectable: true,
      },
      status: {
        title: "Status",
        minWidth: 100,
      },
      result: {
        title: "Result",
        minWidth: 100,
      },
      decision: {
        title: "Decision",
        minWidth: 100,
      },
      overallScore: {
        title: "Overall Score",
        minWidth: 100,
      },
    },
    renderRow: getListRow,
    rowActions,
    findComparator: (candidate, findString) => {
      const { firstName, lastName, email } = candidate.profile;
      const name = `${firstName} ${lastName}`;
      return utils.checkStringIncludeInTexts(findString, [name, email, candidate.id]);
    },
  };

  if (assessment?.collaboratorRole === "Admin") {
    listProps.toolbarActions = {
      addCandidate: {
        title: "Add Candidate",
        icon: "Add",
        onClick: addCandidate,
      },
    };
  }

  return (
    <>
      <CustomList {...listProps} />
      <Dialog />
    </>
  );
}

export default CandidatesTab;
