import React, { useState } from "react";
import {
  Flex,
  Input,
  RadioGroup,
  Text,
  TextArea,
  Grid,
  Dropdown,
  FormDropdown,
  Checkbox,
  Button,
  Loader,
  Provider,
  useFluentContext,
} from "@fluentui/react-northstar";
import { useQueryClient } from "react-query";

import useLoader from "../../hooks/useLoader";
import assessmentAPI from "../../api/assessmentAPI";
import { ASSESSMENT_QUERY_KEY } from "./AssessmentDetails";
import { Assessment, GroupMeta } from "../../types/Assessment";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import logger from "../../services/logger";

const items = Array.from(Array(60).keys()).map((e) => e + "");

const InputSuffix = ({ text }: { text: string }) => (
  <Text content={text} styles={{ alignSelf: "end", marginLeft: "8px" }} />
);

const TimeInput = ({
  seconds,
  onChangeValue,
}: {
  seconds: number;
  onChangeValue: (value: number) => void;
}) => {
  const [min, setMin] = useState(Math.round(seconds / 60));
  const [sec, setSec] = useState(Math.round(seconds % 60));

  const onSecondChange = (second: number) => {
    setSec(second);
    onChangeValue(min * 60 + second);
  };

  const onMinuteChange = (minute: number) => {
    setMin(minute);
    onChangeValue(minute * 60 + sec);
  };

  return (
    <Flex>
      <Dropdown
        items={items}
        fluid
        defaultValue={min + ""}
        styles={{ width: "100px" }}
        getA11ySelectionMessage={{
          onAdd: (item) => {
            item && onMinuteChange(+item as number);
            return item as string;
          },
        }}
      />
      <InputSuffix text="min" />
      <FormDropdown
        items={items}
        styles={{ width: "100px", marginLeft: "16px" }}
        fluid
        defaultValue={sec + ""}
        getA11ySelectionMessage={{
          onAdd: (item) => {
            item && onSecondChange(+item as number);
            return item as string;
          },
        }}
      />
      <InputSuffix text="sec" />
    </Flex>
  );
};

const getElementById = (elementId: string) => document.getElementById(elementId);

const defaultGroupMeta: GroupMeta = {
  practiceQtext:
    "Question: Share with us the first thing you will do after circuit breaker and why (Note: This is a practice question and is not scored.)",
  practiceQtype: "video",
  practicePrepTime: 120,
  practiceMaxAnswerTime: 120,
  completionMessageText: "Congratulations! You've successfully completed the assessment",
  practiceMaxRetakes: "60",
  practiceRetakeDecisionTime: 2,
  practiceMinChars: "1",
  practiceMaxChars: "100",
  practiceQuestionConfig: {
    options: [
      { label: "Locate the Circuit Breaker Box", value: "1" },
      { label: "Identify the Tripped Breaker", value: "2" },
      { label: "Turn Off lights and appliances on the Circuit", value: "3" },
      { label: "Flip the Circuit Breaker Switch", value: "4" },
    ],
  },
  showBrowserCompatText: true,
  showEstimateTimeText: true,
  showAssessmentExpirationText: true,
  showSupportText: true,
  introVideoLocation: null,
  outroVideoLocation: null,
};

type Props = {
  assessment: Assessment;
};

function AdvancedSettingsTab({ assessment }: Props) {
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const { theme } = useFluentContext();
  const columnCount = width <= 700 ? 1 : 2;
  const groupMeta = assessment.groupMeta || defaultGroupMeta;
  const [qType, setQType] = useState(groupMeta.practiceQtype);

  const practiceQtextId = "AS-practiceQtext";
  const completionMessageTextId = "AS-completionMessageText";
  const practiceMaxRetakesId = "AS-practiceMaxRetakes";
  const practiceMinCharsId = "AS-practiceMinChars";
  const practiceMaxCharsId = "As-practiceMaxChars";

  const [practicePrepTime, setPracticePrepTime] = useState(groupMeta.practicePrepTime);
  const [practiceMaxAnswerTime, setPracticeMaxAnswerTime] = useState(
    groupMeta.practiceMaxAnswerTime
  );
  const [practiceRetakeDecisionTime, setPracticeRetakeDecisionTime] = useState(
    groupMeta.practiceRetakeDecisionTime
  );
  let [practiceQuestionConfig, setPracticeQuestionConfig] = useState(
    groupMeta.practiceQuestionConfig?.options
  );
  if(!Array.isArray(practiceQuestionConfig)) {
    practiceQuestionConfig = [];
  }
  const [pageSetting, setPageSetting] = useState({
    showBrowserCompatText: groupMeta.showBrowserCompatText,
    showEstimateTimeText: groupMeta.showEstimateTimeText,
    showAssessmentExpirationText: groupMeta.showAssessmentExpirationText,
    showSupportText: groupMeta.showSupportText,
  });
  const { startLoading, stopLoading, isLoading } = useLoader();
  const [submitResult, setSubmitResult] = useState<"SUCCESS" | "ERROR" | null>(null);

  const onSubmit = async () => {
    if (isLoading) return;

    const practiceQtext =
      (getElementById(practiceQtextId) as any)?.value || groupMeta.practiceQtext;
    const completionMessageText =
      (getElementById(completionMessageTextId) as any)?.value || groupMeta.completionMessageText;
    const practiceMaxRetakes =
      (getElementById(practiceMaxRetakesId) as any)?.value || groupMeta.practiceMaxRetakes;
    const practiceMinChars =
      (getElementById(practiceMinCharsId) as any)?.value || groupMeta.practiceMinChars;
    const practiceMaxChars =
      (getElementById(practiceMaxCharsId) as any)?.value || groupMeta.practiceMaxChars;

    const data = {
      videointerviewGroupId: assessment.videointerviewGroupId,
      groupMeta: {
        practiceQtext,
        completionMessageText,
        practiceMaxRetakes,
        practiceMinChars,
        practiceMaxChars,
        practiceQtype: qType,
        practicePrepTime,
        practiceMaxAnswerTime,
        practiceRetakeDecisionTime,
        practiceQuestionConfig: {
          options: practiceQuestionConfig,
        },
        ...pageSetting,
        introVideoLocation: null,
        outroVideoLocation: null,
      },
    };

    try {
      startLoading();
      await assessmentAPI.editAssessment(assessment.videointerviewGroupId, data);
      await queryClient.refetchQueries([ASSESSMENT_QUERY_KEY, assessment.videointerviewGroupId]);
      stopLoading();
      setSubmitResult("SUCCESS");
    } catch (error) {
      logger.error("Error while submitting data", error);
      stopLoading();
      setSubmitResult("ERROR");
    }
  };

  const secondColumnSpacing = columnCount === 2 ? "ml-4" : "";
  return (
    <Provider theme={theme} style={{ background: "transparent" }}>
      <div className="mt-4">
        <Text content="Practice Question Setup:" weight="bold" size="medium" />

        {/* Question */}
        <Flex column className="pt-5" gap="gap.smaller">
          <Text content="Question" color="brand" weight="semibold" />
          <TextArea
            id={practiceQtextId}
            fluid
            resize="vertical"
            placeholder="Please enter the question"
            defaultValue={groupMeta.practiceQtext}
            style={{ height: "80px" }}
          />
        </Flex>

        {/* Answer this question */}
        <Flex column className="pt-6" gap="gap.smaller">
          <Text
            content="How will candidates answer this question?"
            color="brand"
            weight="semibold"
          />
          <RadioGroup
            defaultCheckedValue={groupMeta.practiceQtype}
            onCheckedValueChange={(e, data) => setQType(data?.value as string)}
            vertical={width <= 600 ? true : false}
            items={[
              {
                key: "1",
                label: "Essay",
                value: "text",
              },
              {
                key: "2",
                label: "Video",
                value: "video",
              },
              {
                key: "3",
                label: "Multiple Choice Question",
                value: "single_choice",
              },
            ]}
          />
        </Flex>

        <Grid columns={`repeat(${columnCount}, 1fr)`}>
          {/* 1 */}
          <Flex column gap="gap.smaller" className="mt-6" space="between">
            <Text
              content="Preparation Time (in minutes and seconds)"
              color="brand"
              weight="semibold"
            />
            <TimeInput
              seconds={practicePrepTime}
              onChangeValue={(value) => setPracticePrepTime(value)}
            />
          </Flex>

          {/* 2 */}
          <Flex column gap="gap.smaller" className={`mt-6 ${secondColumnSpacing}`}>
            <Text
              content="What should be the maximum length of answer for this question (in minutes and seconds)"
              color="brand"
              weight="semibold"
            />
            <TimeInput
              seconds={practiceMaxAnswerTime}
              onChangeValue={(value) => setPracticeMaxAnswerTime(value)}
            />
          </Flex>

          {qType === "video" && (
            <>
              <Flex column gap="gap.smaller" className="mt-6">
                <Text
                  content="Can candidates retake/re-submit an answer? Choose 0 for No."
                  color="brand"
                  weight="semibold"
                />
                <Text
                  content="Choosing a value greater than 0 will allow candidates to delete their answer and re-submit it again as long as it is within the stipulated retake count"
                  weight="light"
                  size="small"
                />
                <Input
                  type="number"
                  id={practiceMaxRetakesId}
                  defaultValue={groupMeta.practiceMaxRetakes + ""}
                  placeholder="1"
                  min={1}
                  max={100}
                  fluid
                />
              </Flex>

              <Flex
                column
                gap="gap.smaller"
                className={`mt-6 ${secondColumnSpacing}`}
                space="between"
              >
                <Text
                  content="Retake decision time (in minutes and seconds)"
                  color="brand"
                  weight="semibold"
                />
                <Text
                  content="Choosing a value greater than 0 will allow candidates to delete their answer and re-submit it again as long as it is within the stipulated retake count"
                  weight="light"
                  size="small"
                />
                <TimeInput
                  seconds={practiceRetakeDecisionTime}
                  onChangeValue={(value) => setPracticeRetakeDecisionTime(value)}
                />
              </Flex>
            </>
          )}

          {qType === "text" && (
            <>
              <Flex column gap="gap.smaller" className="mt-6">
                <Text
                  content="Minimum words required in response"
                  color="brand"
                  weight="semibold"
                />
                <Flex>
                  <Input
                    type="number"
                    styles={{ width: "100px" }}
                    id={practiceMinCharsId}
                    defaultValue={groupMeta.practiceMinChars}
                    placeholder="1"
                    min={1}
                    max={5000}
                    fluid
                  />
                  <InputSuffix text="words" />
                </Flex>
              </Flex>

              <Flex column gap="gap.smaller" className={`mt-6 ${secondColumnSpacing}`}>
                <Text content="Maximum words to use in response" color="brand" weight="semibold" />
                <Flex>
                  <Input
                    type="number"
                    styles={{ width: "100px" }}
                    id={practiceMaxCharsId}
                    defaultValue={groupMeta.practiceMaxChars}
                    placeholder="1"
                    min={1}
                    max={5000}
                    fluid
                  />
                  <InputSuffix text="words" />
                </Flex>
              </Flex>
            </>
          )}

          {qType === "single_choice" && (
            <Flex
              column
              gap="gap.smaller"
              className="mt-6"
              styles={
                columnCount === 2
                  ? {
                      gridColumn: "span 2",
                    }
                  : {}
              }
            >
              <Text content="Maximum words to use in response" color="brand" weight="semibold" />
              <Dropdown
                multiple
                search
                fluid
                items={practiceQuestionConfig.map((option) => ({
                  header: option.label,
                  value: option.value,
                  key: option.value,
                }))}
                placeholder="Start typing a name"
                getA11ySelectionMessage={{
                  onAdd: (item: any) => {
                    setPracticeQuestionConfig((prevArray) => [
                      ...prevArray,
                      { label: item?.header, value: item?.value },
                    ]);
                    return "";
                  },
                  onRemove: (item) => {
                    setPracticeQuestionConfig((prevArray) =>
                      prevArray.filter((option) => option.label !== item)
                    );
                    return "";
                  },
                }}
                defaultValue={groupMeta.practiceQuestionConfig?.options?.map(
                  (option) => option.label
                )}
                noResultsMessage="We couldn't find any matches."
                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
              />
            </Flex>
          )}
        </Grid>

        {/* Landing Page Settings: */}
        <Flex column>
          <Text
            content="Landing Page Settings:"
            className="mt-8 mb-4"
            weight="bold"
            size="medium"
          />
          <Checkbox
            label="Show browser compatability text"
            onChange={(e, { checked }: any) => {
              setPageSetting((prevState) => ({
                ...prevState,
                showBrowserCompatText: checked,
              }));
            }}
            checked={pageSetting.showBrowserCompatText}
          />
          <Checkbox
            label="Show estimation time text"
            checked={pageSetting.showEstimateTimeText}
            onChange={(e, { checked }: any) => {
              setPageSetting((prevState) => ({
                ...prevState,
                showEstimateTimeText: checked,
              }));
            }}
          />
          <Checkbox
            label="Show assessment expiration text"
            checked={pageSetting.showAssessmentExpirationText}
            onChange={(e, { checked }: any) => {
              setPageSetting((prevState) => ({
                ...prevState,
                showAssessmentExpirationText: checked,
              }));
            }}
          />
          <Checkbox
            label="Show technical support text"
            checked={pageSetting.showSupportText}
            onChange={(e, { checked }: any) => {
              setPageSetting((prevState) => ({
                ...prevState,
                showSupportText: checked,
              }));
            }}
          />
        </Flex>

        {/* Text Editor */}
        <Flex column className="mt-6" gap="gap.smaller">
          <Text content="Assessment completion text" color="brand" weight="semibold" />
          <TextArea
            fluid
            resize="vertical"
            style={{ height: "120px" }}
            placeholder="Have a great day!"
            id={completionMessageTextId}
            defaultValue={groupMeta.completionMessageText}
          />
        </Flex>

        {/* Upload video */}
        {/* <Flex column gap="gap.smaller" className="mt-6">
        <Text content="Introduction Video" color="brand" weight="semibold" />
        <Text
          content="This video will be shown to the candidate before beginning the assessment"
          weight="light"
          size="small"
        />
      </Flex>
      <Button primary content="Add Video" className="mt-4" /> */}

        {/* <Flex column gap="gap.smaller" className="mt-6">
        <Text content="Exit Video" color="brand" weight="semibold" />
        <Text
          content="This video will be shown to the candidate after completing the assessment"
          weight="light"
          size="small"
        />
      </Flex>
      <Button primary content="Add Video" className="mt-4" /> */}

        <Flex className="mt-8" gap="gap.medium">
          <Button
            primary
            content="Save"
            size="medium"
            style={{ height: "40px" }}
            onClick={onSubmit}
          />
          {isLoading && <Loader />}
        </Flex>
        {submitResult === "SUCCESS" && (
          <Text
            className="mt-2"
            content="The settings have been updated successfully"
            color="green"
          />
        )}
        {submitResult === "ERROR" && (
          <Text
            className="mt-2"
            content="Fail to update the setting. Please retry again."
            color="red"
          />
        )}
      </div>
    </Provider>
  );
}

export default AdvancedSettingsTab;
