const ROUTES = Object.freeze({
  root: "/",
  xPlay: "/xplay",

  // Channel Tab
  configChannelTab: "/xtab",
  channelTab: "/ctab/:assessmentString/:entityId",
  removeChannelTab: "/ctab/:assessmentString/:entityId/r",

  // Personal Tab
  assessmentTab: "/st/assessments",
  ratingCriteriaTab: "/st/rating-criteria",
  questionBankTab: "/st/question-bank",
});

export default ROUTES;
