import * as microsoftTeams from "@microsoft/teams-js";
import { config } from "../Config";

export const getAuthToken = async () => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (result) => resolve(result),
      failureCallback: (error) => reject(error),
    });
  });
};

export const showGraphConsent = async (): Promise<{
  accessToken: any;
  expiresIn: any;
} | null> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.authenticate({
      url: `${config.appUrl}/astart.html`,
      width: 600,
      height: 535,
      successCallback: (resultKey) => {
        if (resultKey) {
          const tokenString = localStorage.getItem(resultKey);
          const tokenObject = JSON.parse(tokenString as string);
          resolve(tokenObject);
        } else {
          reject("Key is null");
        }
      },
      failureCallback: (reason) => reject(reason),
    });
  });
};

export const showAdminConsent = async () => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.authenticate({
      url: `${config.appUrl}/admin-start-consent.html`,
      width: 600,
      height: 535,
      successCallback: (consentProvided) => {
        resolve(consentProvided);
      },
      failureCallback: (reason) => reject(reason),
    });
  });
};
