import Axios from "axios";
import jwt_decode from "jwt-decode";
import { FunctionComponent } from "react";
import React, { useEffect, useState } from "react";

import Welcome from "../../sections/Welcome";
import { config } from "../../Config";
import { xfetch } from "../xfetch";
import useLoader from "../../hooks/useLoader";
import GraphContext, { GraphToken } from "./GraphContext";
import FullScreenLoader from "../../components/FullScreenLoading";
import { getAuthToken, showGraphConsent } from "../../utils/authTokenUtils";
import logger from "../logger";

const withGraphAuthentication = (WrapperComponent: FunctionComponent) => {
  return (props: any) => {
    // Inner Component
    const { startLoading, stopLoading, isLoading, loadingMessage } = useLoader();
    const [graphToken, setGraphToken] = useState<GraphToken>({
      accessToken: "",
      expireDate: new Date(),
    });

    const [applicationLevelToken, setApplicationLevelToken] = useState<GraphToken | null>(null);
    const [isConsentProvided, setConsentProvided] = useState(false);

    const fetchApplicationLevelToken = async () => {
      try {
        const response = await xfetch("xapptoken", "post", null);
        const date = new Date();
        date.setSeconds(date.getSeconds() + response.expires_in);
        const decoded = jwt_decode(response.access_token) as any;
        const token = {
          accessToken: response.access_token as string,
          expireDate: date,
          roles: decoded?.roles,
        };
        setApplicationLevelToken(token);
        return token;
      } catch (error) {
        logger.error(error);
        return null;
      }
    };

    // const provideAdminConsent = async () => {
    //   try {
    //     startLoading("Loading Account...");
    //     const response = await showAdminConsent();
    //     logger.debug("Consent Provided", response);
    //     const accessToken = await fetchApplicationLevelToken();
    //     logger.debug("Access Token: ", accessToken);
    //     setConsentProvided(true);
    //   } catch (error) {
    //     setConsentProvided(false);
    //     logger.error("Consent fail", error);
    //   }
    //   stopLoading();
    // };

    const exchangeClientTokenForServerToken = async () => {
      try {
        const xToken = await getAuthToken();
        const response = await Axios({
          method: "post",
          url: `${config.botUrl}/api/graphToken`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({ xToken }),
        });
        if (response?.data?.access_token) {
          return { accessToken: response.data.access_token, expiresIn: response.data.expires_in };
        }
      } catch (error) {
        logger.error(error);
      }
      return null;
    };

    const requestDelegateLevelToken = async () => {
      try {
        startLoading("Authenticating...");
        let response = await exchangeClientTokenForServerToken();
        logger.debug("On-Behalf-Flow response: ", response);
        if (!response) {
          response = await showGraphConsent();
        }
        logger.debug("Consent Provided", response);
        const date = new Date();
        date.setSeconds(date.getSeconds() + response?.expiresIn);
        setGraphToken({
          accessToken: response?.accessToken,
          expireDate: date,
        });
        setConsentProvided(true);
      } catch (error) {
        setConsentProvided(false);
        logger.error("Consent fail", error);
      }
      stopLoading();
    };

    const authenticate = async () => {
      try {
        startLoading("Loading Account...");
        const ssoToken = await getAuthToken();
        logger.debug(ssoToken);
        await requestDelegateLevelToken();
        await fetchApplicationLevelToken();
      } catch (error) {
        logger.error(error);
      }
      stopLoading();
    };

    const resetGraphToken = () => {
      requestDelegateLevelToken();
    };

    useEffect(() => {
      authenticate();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return <FullScreenLoader label={loadingMessage} />;
    }

    if (!isConsentProvided) {
      return <Welcome authButtonMethod={authenticate} />;
    }

    return (
      <GraphContext.Provider
        value={{
          graphToken,
          resetGraphToken,
          applicationLevelToken,
          refetchApplicationLevelToken: fetchApplicationLevelToken,
        }}
      >
        <WrapperComponent {...props} />
      </GraphContext.Provider>
    );
  };
};

export default withGraphAuthentication;
