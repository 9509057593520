import logger from "../services/logger";
import { xfetch } from "../services/xfetch";
import { getAuthToken } from "../utils/authTokenUtils";

const saveTabContext = async (data: any) => {
  try {
    const token = await getAuthToken();
    return await xfetch("xtabconfig", "post", { ...data, token });
  } catch (error) {
    logger.error(error);
    return {
      error: {
        message: "Unknown error",
      },
    };
  }
};

export { saveTabContext };
