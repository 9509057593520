import { xfetch } from "../services/xfetch";
import { BaseApiPath } from "../utils/constants";

const basePath = `${BaseApiPath}/hybridquestions`;

const getAssessmentQuestions = async (assessmentId: number | string) => {
  const xpath = `${basePath}/${assessmentId}`;
  return xfetch(xpath, "get", null);
};

// TODO: set limit and skip
const getQuestionCriteria = async () => {
  const xpath = `${BaseApiPath}/videointerviewgroupcriteria?_=1647326487002&limit=50&skip=0&searchKey=`;
  return xfetch(xpath, "get", null);
};

interface Question {
  questionName: string;
  questionType: string;
  categories: any[];
  criterias: any[];
  prepTime: number;
  maxAnswerTime?: number;
  minWords?: number;
  maxWords?: number;
  maxRetakeTime?: number;
  retakeDecisionTime?: number;
  assessmentId: number;
  companyId: number;
}

const addQuestion = async (question: Question) => {
  let xdata: any = {
    questionName: "",
    questionUrl: null,
    questionType: "video",
    prepTime: null,
    minWords: null,
    maxWords: null,
    maxAnswerTime: null,
    companyId: null,
    assessmentId: null,
    categories: [],
    criterias: [],
    maxRetakeTime: null,
    retakeDecisionTime: null,
    weightage: null,
    maxscore: null,
    questionConfig: null,
    redFlagsConfig: null,
    answerConfig: null,
    isAnyOptionCorrect: null,
    allowOnlySingleChoice: null,
    questionFileUrl: null,
  };
  xdata = { ...xdata, ...question };
  return xfetch(basePath, "post", xdata);
};

const updateQuestion = async (question: Question & { hybridQuestionId: string }) => {
  return xfetch(basePath, "patch", question);
};

const deleteQuestion = async (hybridQuestionId: string) => {
  const response = await xfetch(basePath, "delete", { hybridQuestionId });
  if (response?.error) {
    throw new Error("Error while deleting question");
  }
};

const questionAPI = {
  getAssessmentQuestions,
  getQuestionCriteria,
  addQuestion,
  updateQuestion,
  deleteQuestion,
};

export default questionAPI;
