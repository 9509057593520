import axios from "axios";

import logger from "./logger";
import { getAuthToken } from "../utils/authTokenUtils";
import { baseURL, ErrorCode } from "../utils/constants";

type ApiMethod = "get" | "post" | "delete" | "patch";

const xfetch = async (xpath: string, xmethod: ApiMethod, xdata: {} | null = null) => {
  let rData = {
    xpath: xpath,
    xmethod: xmethod,
    xdata: xdata,
  };

  // Teams store token in cache, so we can fetch multiple time
  const token = await getAuthToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(baseURL, rData, config);
    if (res.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw res.data?.error;
    }
    logger.info(`Successful API call (${xpath})`);
    logger.debug("API Response: ", res);
    return res.data;
  } catch (error) {
    logger.debug(`Error Calling API (${xpath})`, { error });
    if ((error as any)?.code && (error as any)?.message) {
      throw error;
    }
    throw handleFailure(error);
  }
};

const handleFailure = (error: any) => {
  let code = 0;
  let message = "";
  let data = {};

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const resData = error.response.data;
    code = resData?.code || error.response.status || ErrorCode.API_ERROR;
    message = resData?.message || resData?.error?.message;
    data = resData;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    message = error.message;
    code = ErrorCode.NETWORK_NOT_REACHABLE;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
    code = ErrorCode.ERROR_SETTING_UP_REQUEST;
  }

  return {
    message,
    code,
    data,
  };
};

export { xfetch };
