import React, { Suspense, useEffect } from "react";
import { appInitialization } from "@microsoft/teams-js";
import { Provider as ReduxProvider } from "react-redux";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { QueryClient, QueryClientProvider } from "react-query";

import store from "./store/store";
import Navigator from "./routes/Navigator";
import ThemeProvider from "./themes/ThemeProvider";
import Snackbar from "./components/Snackbar/Snackbar";
import AuthProvider from "./services/authenticator/AuthProvider";

import "./services/i18n"; // initializing i18n
import logger from "./services/logger";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  useEffect(() => {
    logger.info("Version: 2.1.9");
    initializeIcons();
    appInitialization.notifySuccess();
  }, []);

  return (
    <ThemeProvider>
      <Suspense fallback="Loading...">
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <Navigator />
              <Snackbar />
            </ReduxProvider>
          </QueryClientProvider>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
