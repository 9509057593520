import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Toolbar } from "@fluentui/react-teams";
import React, { useEffect, useState } from "react";

import MetricCard from "./MetricCard";
import CandidateDetail from "./CandidateDetail";
import useLoader from "../../hooks/useLoader";
import useSnackbar from "../../hooks/useSnackbar";
import questionAPI from "../../api/questionAPI";
import assessmentAPI from "../../api/assessmentAPI";
import FullScreenLoader from "../../components/FullScreenLoading";
import { questionActions } from "../../store/slices/questionSlice";
import AssessmentDetailTab from "./AssessmentDetailTab";
import openQuestionCard from "../../adaptiveCards/QuestionCard/openQuestionCard";
import logger from "../../services/logger";

type Props = {
  assessmentId: number;
  goBack: () => void;
};

export const ASSESSMENT_QUERY_KEY = "Assessment";

const AssessmentDetails = ({ assessmentId, goBack }: Props) => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [questionCriteria, setQuestionCriteria] = useState([]);
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader();
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null);

  const {
    data: assessment,
    error,
    isLoading: listLoader,
  } = useQuery([ASSESSMENT_QUERY_KEY, assessmentId], () =>
    assessmentAPI.getAssessmentById(assessmentId)
  );

  useEffect(() => {
    if (error && snackbar && goBack) {
      logger.error("Error while fetching Assessment", error);
      snackbar("Failure while fetching Assessment", "ERROR", "LONG");
      goBack();
    }
  }, [error, snackbar, goBack]);

  const fetchData = async () => {
    try {
      startLoading("Loading...");
      const questionCriteria = await questionAPI.getQuestionCriteria();
      setQuestionCriteria(questionCriteria.items);
    } catch (err) {
      logger.error("Error: ", err);
    }
    stopLoading();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addQuestion = async () => {
    if (!assessment) return;
    try {
      const result = await openQuestionCard({
        assessmentName: assessment.groupName,
        assessmentId: assessment.videointerviewGroupId,
        questionCriteria,
        questionCategory: assessment?.groupConfig?.groupCategory || [],
      });

      await questionAPI.addQuestion({
        ...result,
        assessmentId: assessment.videointerviewGroupId,
        companyId: assessment.companyId,
      });

      const { hybridQuestions } = await questionAPI.getAssessmentQuestions(assessmentId);
      dispatch(questionActions.setQuestions(hybridQuestions));
      snackbar("Question created successfully", "SUCCESS", "LONG");
    } catch (error) {
      if ((error as any)?.code) snackbar("Fail to create question.", "ERROR", "LONG");
      logger.error("Error: while fetching questions ", error);
    }
  };

  const addCandidate = () => {};

  const handleInteraction = (e: any) => {
    switch (e.subject) {
      case "assessments":
        goBack(); // Go back to Assessments list
        break;
      case "add-question":
        addQuestion();
        break;
      case "add-candidate":
        addCandidate();
        break;
      default:
        logger.debug("Not handled actions");
        break;
    }
  };

  const toolbarProps = {
    actionGroups: {
      g1: {
        a1: {
          title: `Assessments:  ${assessment?.groupName}`,
          subject: "assessments",
          icon: "ArrowLeft",
        },
        a2: {
          title: "Add Question",
          icon: "ToDoList",
          subject: "add-question",
        },
        // a3: {
        //   title: "Add Candidate",
        //   icon: "ParticipantAdd",
        //   subject: "add-candidate",
        // },
        // a4: {
        //   title: "Clone Assessment",
        //   icon: "WindowRestore",
        //   subject: "clone-assessment",
        // },
        // a5: {
        //   title: "Edit Assessment",
        //   icon: "Edit",
        //   subject: "edit-assessment",
        // },
        // a6: {
        //   title: "Close Assessment",
        //   icon: "Close",
        //   subject: "close-assessment",
        // },
      },
    },
    onInteraction: (a: any) => {
      logger.debug("X0PA: Assessment Details onInteraction: ", a);
      handleInteraction(a);
    },
  };

  if (isLoading || listLoader) {
    return (
      <div className="h-screen w-screen">
        <FullScreenLoader label={loadingMessage || "Loading..."} />
      </div>
    );
  }

  if (selectedCandidateId) {
    return (
      <CandidateDetail
        candidateId={selectedCandidateId}
        goBack={() => setSelectedCandidateId(null)}
      />
    );
  }

  if (assessment) {
    return (
      <div>
        <div style={{ position: "sticky", top: 0, zIndex: 2000 }}>
          <Toolbar {...toolbarProps} />
        </div>
        <MetricCard status={assessment?.statusMap} className="px-2" />
        <div style={{ width: "100%", padding: "14px" }}>
          <AssessmentDetailTab
            assessment={assessment}
            tabToShow={{
              candidates: true,
              collaborators: true,
              setup: true,
              advancedSettings: true,
              notification: true,
            }}
            scope="PERSONAL"
            candidateTabProps={{
              showCandidateDetailScreen: setSelectedCandidateId,
            }}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default AssessmentDetails;
