export const InProduction = process.env.REACT_APP_ENV !== "dev";

const domain = InProduction ? "teams.x0pa.ai" : "x0pa-springct-dev.loca.lt";
const clientId = "d304c4b4-8353-463f-910d-3819e3c214bd";
const botUrl = InProduction ? "https://lb.x0pa.ai" : process.env.REACT_APP_BOT_LOCAL_URL;

export const config = {
  appId: clientId,
  botUrl,
  appUrl: `https://${domain}`,
  redirectUri: `https://${domain}`,
  scopes: ["openid", "profile", "email", "user.read", "offline_access"],
  customscopes: [`api://${domain}/${clientId}/access_as_user`],
  applicationLevelScopes: ["TeamsAppInstallation.ReadWriteSelfForUser.All"], // Add all the required application level scopes
  postLogoutRedirectUri: `https://${domain}/loggedout`,
};
