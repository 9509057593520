import React, { useEffect, useState } from "react";
import { Text } from "@fluentui/react-northstar";
import { useQuery, useQueryClient } from "react-query";

import utils from "../../utils/utils";
import { config } from "../../Config";
import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import useDialog from "../../hooks/useDialog";
import { ErrorCode } from "../../utils/constants";
import useSnackbar from "../../hooks/useSnackbar";
import CustomList from "../../components/CustomList";
import collaboratorAPI from "../../api/collaboratorAPI";
import useGraphService from "../../services/graphAPI/useGraphService";
import { executeDeepLink, getTeamsContext } from "../../utils/teamsUtils";
import openCollaboratorCard from "../../adaptiveCards/CollaboratorCard/openCollaboratorCard";

const CollaboratorRole = {
  1: "Administrator",
  2: "Reviewer",
};
const COLLABORATOR_QUERY_KEY = "collaborators";

// scope: "CHANNEL" | "PERSONAL";
function CollaboratorsTab({ assessment, scope = "PERSONAL" }) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { getUserConfirmation, Dialog, USER_REJECTED_ERROR } = useDialog();
  const { sendActivityFeed, sendChannelNotification, getChannelMembers, client } =
    useGraphService();
  const {
    data: collaborators,
    error,
    isLoading: listLoader,
  } = useQuery([COLLABORATOR_QUERY_KEY, assessment.videointerviewGroupId], () =>
    collaboratorAPI.getAssessmentCollaborators(assessment.videointerviewGroupId)
  );

  const { startLoading, stopLoading, isLoading, loadingMessage } = useLoader();
  const [userList, setUserList] = useState();

  useEffect(() => {
    if (error && snackbar) {
      logger.error("Error while fetching collaborators ", error);
      snackbar("Failure to load collabortors: try-again", "ERROR", "LONG");
    }
  }, [error, snackbar]);

  const setFilterUserList = async (collaborators) => {
    let filterList = [];
    try {
      startLoading();
      const { userPrincipalName } = await getTeamsContext();
      const users = await collaboratorAPI.getCollaboratorsUserList();
      const collaboratorIds = collaborators.map((item) => item.userinfo.userId);
      if (scope === "CHANNEL") {
        const members = await getChannelMembers();
        const memberEmails = members.map((m) => m.email.toLowerCase());
        filterList = users.filter((u) => {
          const email = u?.userInfo?.email.toLowerCase();
          return (
            email &&
            memberEmails.includes(email) &&
            email !== userPrincipalName.toLowerCase() &&
            !collaboratorIds.includes(u?.userInfo?.userId)
          );
        });
      } else {
        filterList = users.filter((u) => {
          const email = u?.userInfo?.email.toLowerCase();
          return (
            email &&
            email !== userPrincipalName.toLowerCase() &&
            !collaboratorIds.includes(u?.userInfo?.userId)
          );
        });
      }
      console.log("filterList",filterList,users,collaboratorIds);
      setUserList(filterList);
    } catch (error) {
      logger.error("Error while fetching the user list", error);
    }
    stopLoading();
  };

  useEffect(() => {
    if (client && collaborators) {
      setFilterUserList(collaborators);
    }
  }, [client, collaborators]);

  const removeCollaborator = async (collaboratorId) => {
    try {
      await getUserConfirmation({
        header: "Delete Collaborator",
        content: "Are you sure you want to delete this user?",
      });
      startLoading("Deleting...");
      await collaboratorAPI.deleteAssessmentCollaborators(
        assessment.videointerviewGroupId,
        collaboratorId
      );
      await queryClient.refetchQueries([COLLABORATOR_QUERY_KEY, assessment.videointerviewGroupId]);
      snackbar("Collaborator deleted successfully", "SUCCESS");
    } catch (err) {
      if (err?.code === USER_REJECTED_ERROR) {
        return;
      }
      logger.error("Error while deleting collaborator", err);
      snackbar("Fail to delete Collaborator", "ERROR");
    }
    stopLoading();
  };

  const sendCollaboratorAddNotification = async (email, assessment) => {
    if (scope === "PERSONAL") {
      try {
        // Send Activity Feed notification to redirect user to Personal Tab
        await sendActivityFeed({
          activityType: "addedAsCollaborator",
          receiverEmail: email,
          entityId: "xassessments",
          message: "You are added as a collaborator in Assessment",
          subEntityId: assessment.videointerviewGroupId + "",
          content: `${assessment.groupName} - ${assessment.videointerviewGroupId}`,
          deepLink: `${config.appUrl}/st/assessments`,
        });
      } catch (error) {
        if (error.code === ErrorCode.APPLICATION_PERMISSION_NOT_GRANTED) {
          await getUserConfirmation({
            header:
              "Unable to notify the collaborator on Microsoft Teams - Admin Permissions Required",
            content:
              "The collaborator has been successfully added to the assessment. We wanted to notify the collaborator on Microsoft Teams, but the user doesn't seem to have the X0PA ROOM application installed on Teams. Please request your Microsoft Teams administrator to grant X0PA ROOM app the required permissions for us to install and notify the collaborator directly on Microsoft Teams.",
            confirmButtonLabel: "Continue",
            cancelButtonLabel: {
              content: "View the steps to grant permission",
              onClick: () => {
                logger.error("Navigating to Help page");
                // User has click the view permission button
                executeDeepLink(
                  "https://teams.microsoft.com/l/entity/d304c4b4-8353-463f-910d-3819e3c214bd/x0papersonalhelp?context=%7B%22subEntityId%22:%20%22grant-admin-consent%22%7D"
                );
              },
            },
          }).catch(() => {});
        } else {
          throw error;
        }
      }
    }

    if (scope === "CHANNEL") {
      const iconType = assessment.interviewType === "fixed" ? "pin-174.png" : "shuffle-274.png";
      const mediaImage = `https://xpastatic.blob.core.windows.net/xassetspub/teams/${iconType}`;
      // Send Channel Notification
      sendChannelNotification(email, {
        id: assessment.videointerviewGroupId,
        name: assessment.groupName,
        createdAt: assessment.createdAt,
        landingPageInfo: assessment.landingPageInfo,
        imageUrl: mediaImage,
      });
    }
  };

  const addCollaborator = async () => {
    try {
      const result = await openCollaboratorCard(
        {
          assessmentId: assessment.videointerviewGroupId + "",
          assessmentName: assessment.groupName,
        },
        userList
      );
      startLoading("Adding...");
      await collaboratorAPI.addAssessmentCollaborator(assessment.videointerviewGroupId, result);

      const user = userList.find((f) => f.userInfo.userId + "" === result.userId);
      startLoading("Sending Notification...");
      await sendCollaboratorAddNotification(user.userInfo.email, assessment);

      await queryClient.refetchQueries([COLLABORATOR_QUERY_KEY, assessment.videointerviewGroupId]);
      snackbar("Collabortor added successfully", "SUCCESS");
    } catch (error) {
      if (error?.code) {
        // API error
        snackbar("Fail to add Collabortor", "ERROR");
      }
      logger.error("Error while adding colloborator", error);
    }
    stopLoading();
  };

  const rowActions = {
    remove: { title: "Remove", icon: "TrashCan", onClick: removeCollaborator },
  };

  const getListRow = (collaborator) => {
    return {
      name: {
        content: (
          <Text weight="bold" color="brand">
            {collaborator.userinfo.firstname} {collaborator.userinfo.lastname}
          </Text>
        ),
      },
      role: CollaboratorRole[collaborator.roleId],
    };
  };

  const listProps = {
    loading: listLoader || isLoading,
    loadingMessage: loadingMessage || "Loading...",
    items: collaborators,
    toolbarActions: {},
    itemIdKeyName: "videointerviewgroupCollaboratorId",
    columns: {
      name: {
        title: "User Name",
      },
      role: {
        title: "Role",
      },
    },
    renderRow: getListRow,
    rowActions,
    findComparator: (collaborator, findString) => {
      const { firstname, lastname } = collaborator.userinfo;
      return utils.checkStringIncludeInTexts(findString, [`${firstname} ${lastname}`]);
    },
  };

  if (assessment.collaboratorRole === "Admin") {
    listProps.toolbarActions = {
      addCollaborator: {
        title: "Add Collaborator",
        icon: "Add",
        onClick: addCollaborator,
      },
    };
  }
  return (
    <>
      <CustomList {...listProps} />
      <Dialog />
    </>
  );
}

export default CollaboratorsTab;
