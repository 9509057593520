import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react-tabs";
import { Card, useFluentContext } from "@fluentui/react-northstar";

import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import questionAPI from "../../api/questionAPI";
import { Assessment } from "../../types/Assessment";
import FullScreenLoader from "../../components/FullScreenLoading";
import AssessmentSetup from "./AssessmentSetup";
import CandidatesTab from "./CandidatesTab";
import CollaboratorsTab from "./CollaboratorsTab";
import NotificationsTab from "./NotificationsTab";
import AdvancedSettingsTab from "./AdvancedSettingsTab";

type Props = {
  assessment: Assessment;
  tabToShow: {
    setup?: boolean;
    candidates?: boolean;
    collaborators?: boolean;
    advancedSettings?: boolean;
    notification?: boolean;
    analytics?: boolean;
  };
  scope: "CHANNEL" | "PERSONAL";
  candidateTabProps?: {
    showCandidateDetailScreen: (id: string) => void;
  };
};

export const ASSESSMENT_QUERY_KEY = "Assessment";

const AssessmentDetailTab = ({ assessment, tabToShow, scope, candidateTabProps }: Props) => {
  const { theme } = useFluentContext();
  const [questionCriteria, setQuestionCriteria] = useState([]);
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader({
    isLoading: true,
    message: "Loading Data...",
  });

  const fetchData = async () => {
    try {
      startLoading("Loading...");
      const questionCriteria = await questionAPI.getQuestionCriteria();
      setQuestionCriteria(questionCriteria.items);
    } catch (err) {
      logger.error("Error: ", err);
    }
    stopLoading();
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-screen w-screen">
        <FullScreenLoader label={loadingMessage || "Loading..."} />
      </div>
    );
  }

  const hoverbackgroundStyle = {
    background:
      theme.siteVariables.theme !== "teamsTheme"
        ? theme.siteVariables.transparentColors.onyx[200]
        : undefined,
    color: theme.siteVariables.bodyColor,
  };

  if (assessment) {
    return (
      <div className="mt-4">
        <Card fluid elevated>
          <Pivot
            overflowBehavior="menu"
            styles={{
              link: {
                color:
                  theme.siteVariables.theme !== "teamsTheme"
                    ? theme.siteVariables.transparentColors.silver[300] ||
                      theme.siteVariables.bodyColor
                    : undefined,
                ":hover": hoverbackgroundStyle,
                ":active": hoverbackgroundStyle,
              },
              overflowMenuButton: {
                color: theme.siteVariables.bodyColor,
                ":hover": hoverbackgroundStyle,
                ":active": hoverbackgroundStyle,
              },
              linkInMenu: {
                zIndex: 1000,
                color: theme.siteVariables.bodyColor,
                background: theme.siteVariables.bodyBackground,
                borderColor: theme.siteVariables.bodyBackground,
                ":hover": {
                  background:
                    theme.siteVariables.theme !== "teamsTheme"
                      ? theme.siteVariables.transparentColors.onyx[800]
                      : undefined,
                  color: theme.siteVariables.bodyColor,
                },
                ":active": {
                  background:
                    theme.siteVariables.theme !== "teamsTheme"
                      ? theme.siteVariables.transparentColors.onyx[800]
                      : undefined,
                  color: theme.siteVariables.bodyColor,
                },
              },
              root: {
                zIndex: 1000,
              },
              linkIsSelected: {
                fontWeight: "800",
                color: theme.siteVariables.bodyColor,
                ":before": {
                  background: theme.siteVariables.colorScheme.brand.foreground,
                },
              },
            }}
          >
            {tabToShow?.setup && (
              <PivotItem headerText="Assessment Setup">
                <AssessmentSetup assessment={assessment} questionCriteria={questionCriteria} />
              </PivotItem>
            )}
            {tabToShow?.candidates && (
              <PivotItem headerText="Candidates">
                <CandidatesTab assessment={assessment} {...candidateTabProps} />
              </PivotItem>
            )}
            {tabToShow?.collaborators && (
              <PivotItem headerText="Collaborators">
                <CollaboratorsTab assessment={assessment} scope={scope} />
              </PivotItem>
            )}
            {tabToShow?.analytics && (
              <PivotItem headerText="Analytics">
                <p>Analytics</p>
              </PivotItem>
            )}
            {tabToShow?.advancedSettings && (
              <PivotItem headerText="Advanced Settings">
                <AdvancedSettingsTab assessment={assessment} />
              </PivotItem>
            )}
            {tabToShow?.notification && (
              <PivotItem headerText="Notifications">
                <NotificationsTab assessment={assessment} />
              </PivotItem>
            )}
          </Pivot>
        </Card>
      </div>
    );
  }
  return null;
};

export default AssessmentDetailTab;
