import * as MicrosoftTeams from "@microsoft/teams-js";
import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
  Header,
  Image,
  Button,
  Loader,
} from "@fluentui/react-northstar";
import moment from "moment";
import { useTranslation } from "react-i18next";

import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import { Assessment } from "../../types/Assessment";
import assessmentAPI from "../../api/assessmentAPI";
import { useHistory, useParams } from "react-router-dom";
import MetricCard from "../../sections/assessment/MetricCard";
import AssessmentDetailTab from "../../sections/assessment/AssessmentDetailTab";
import withGraphAuthentication from "../../services/graphAPI/withGraphAuthentication";
import { useAuthentication } from "../../services/authenticator/AuthProvider";
import CandidateDetail from "../../sections/assessment/CandidateDetail";

const ChannelTab = () => {
  const { push, location } = useHistory();
  const [assessment, setAssessment] = useState<Assessment>();
  const { isLoading, stopLoading } = useLoader({ isLoading: true, message: "" });
  const params = useParams<{ assessmentString: string; entityId: string }>();
  const { t } = useTranslation("translation", { keyPrefix: "channelTab" });
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null);

  const { logout } = useAuthentication();

  const assessmentInfo = useCallback(async () => {
    try {
      const usp = new URLSearchParams(window.location.search);
      const assessmentId = usp.get("vig");
      const assessment = await assessmentAPI.getAssessmentById(assessmentId || 0);
      setAssessment(assessment);
    } catch (error) {
      logger.error(error);
    }
    stopLoading();
  }, [stopLoading]);

  // const handleViewResponse = useCallback(
  //   (interviewId) => {
  //     const { search } = location;
  //     const { assessmentString, entityId } = params;
  //     if (assessmentString && entityId) {
  //       push(`/cres/${assessmentString}/${entityId}/${interviewId}/${search}`);
  //     }
  //   },
  //   [location, params, push]
  // );

  useEffect(() => {
    const usp = new URLSearchParams(window.location.search);
    const noRedirect = usp.get("novirredirect");
    MicrosoftTeams.initialize();
    MicrosoftTeams.getContext((context) => {
      const { subEntityId } = context || {};
      if (subEntityId?.includes("vires:") && !noRedirect) {
        const ctxInterviewId = subEntityId?.split("vires:")[1];
        if (ctxInterviewId) {
          // handleViewResponse(ctxInterviewId);
          setSelectedCandidateId(ctxInterviewId);
        }
      }
    });
    assessmentInfo();
  }, [assessmentInfo]);

  const { groupName, createdAt, groupConfig, statusMap } = assessment || {};
  const { interviewType } = groupConfig || {};
  const iconType = interviewType === "fixed" ? "pin-174.png" : "shuffle-274.png";
  const mediaImage = `https://xpastatic.blob.core.windows.net/xassetspub/teams/${iconType}`;

  if (isLoading) {
    return (
      <Flex style={{ height: "100vh" }} vAlign="center" hAlign="center">
        <Loader label="Loading Assessment..." />
      </Flex>
    );
  }

  if (selectedCandidateId) {
    return (
      <CandidateDetail
        candidateId={selectedCandidateId}
        goBack={() => setSelectedCandidateId(null)}
      />
    );
  }
  return (
    <div style={{ padding: 16 }}>
      <Flex space="between" vAlign="center">
        <Text size="larger" weight="bold">
          {t("assessmentInfo")}
        </Text>
        <Button content={t("btn.signOut")} onClick={logout} />
      </Flex>

      <Flex gap="gap.small" className="mt-4" wrap={true}>
        <div style={{ flex: 1 }}>
          <Card fluid>
            <CardHeader>
              <Flex gap="gap.small" vAlign="center">
                <div>
                  <Image src={mediaImage} circular style={{ width: 40 }} />
                </div>
                <Flex column>
                  <Header as="h2" style={{ marginTop: 0, marginBottom: 0 }}>
                    {groupName}
                  </Header>
                  <Text content={moment(createdAt).format("Do MMM YYYY")} size="small" />
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              <Header as="h4" className="mb-0">
                {t("label.summary")}
              </Header>
              <MetricCard status={statusMap} />
            </CardBody>
          </Card>
        </div>
      </Flex>
      {assessment && assessment?.collaboratorRole !== "UnAuthorized" && (
        <AssessmentDetailTab
          assessment={assessment}
          tabToShow={{ candidates: true, collaborators: true, setup: true }}
          scope="CHANNEL"
          candidateTabProps={{
            showCandidateDetailScreen: setSelectedCandidateId,
          }}
        />
      )}
    </div>
  );
};

export default withGraphAuthentication(ChannelTab);
