import { useSelector, useDispatch } from "react-redux";

import logger from "../../services/logger";
import useLoader from "../../hooks/useLoader";
import useSnackbar from "../../hooks/useSnackbar";
import assessmentAPI from "../../api/assessmentAPI";
import openAssessmentCard from "../../adaptiveCards/AssessmentCard/openAssessmentCard";
import { assessmentActions, assessmentSelector } from "../../store/slices/assessmentSlice";
import {
  convertDateToString,
  convertDateToTimestamp,
  getYesterdayDate,
} from "../../utils/dateUtils";
import { useTranslation } from "react-i18next";

function useAssessments() {
  const assessments = useSelector(assessmentSelector.assessments);
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader();
  const { t } = useTranslation("translation", { keyPrefix: "assessment" });
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const getAssessmentById = (id: number) => {
    return assessments.find((ele) => ele?.id === id);
  };

  const fetchAssessments = async () => {
    try {
      const response = await assessmentAPI.listAssessments();
      const assessmentFetched = response?.Videointerviewgroups.map((item: any, index: any) => ({
        ...item,
        id: index,
      }));
      dispatch(assessmentActions.setAssessments(assessmentFetched));
      return assessmentFetched;
    } catch (error) {
      snackbar(t("error.fetchingAssessment"), "ERROR", "LONG");
      logger.error("Error: while fetching assessments ", error);
    }
    return [];
  };

  const addNewAssessments = async () => {
    try {
      console.log("Adding Assessment call");
      const result = await openAssessmentCard();
      console.log("OPEN ASSESSMENT CARD RESULT", result);
      // API: add new assessment
      const response = await assessmentAPI.addAssessment(result);
      console.log("ADD ASSESSMENT RESPONSE", response);
      // API: get assessment by id
      const assessment = await assessmentAPI.getAssessmentById(response.videointerviewGroupId);
      dispatch(assessmentActions.addAssessment(assessment));
      snackbar(t("success.createAssessment"), "SUCCESS");
    } catch (error) {
      if ((error as any)?.code) snackbar(t("fail.createAssessment"), "ERROR");
      logger.error("Error while adding new assessment: ", error);
    }
  };

  const editAssessment = async (id: number) => {
    try {
      const selectedAssessment = getAssessmentById(id);
      if (!selectedAssessment) return;

      const result = await openAssessmentCard(selectedAssessment, "EDIT");
      logger.debug("X0PA: Success ", result);
      const assessment = await assessmentAPI.editAssessment(
        selectedAssessment.videointerviewGroupId,
        result
      );
      dispatch(assessmentActions.editAssessment(assessment));
      snackbar(t("success.editAssessment"), "SUCCESS");
    } catch (error) {
      if ((error as any)?.code) snackbar(t("fail.createAssessment"), "ERROR");
      logger.error("Error while editing assessment: ", error);
    }
  };

  const closeAssessments = async (ids: number[]) => {
    try {
      const yesterday = getYesterdayDate();
      ids.forEach(async (id) => {
        const assessment = getAssessmentById(id);
        if (!assessment) return;
        const response = await assessmentAPI.closeAssessment(assessment?.videointerviewGroupId, {
          expirationDate: convertDateToString(yesterday),
          expiresAt: convertDateToTimestamp(yesterday).toString(),
        });
        dispatch(assessmentActions.editAssessment({ assessment, ...response }));
      });
      snackbar(t("success.closeAssessment"), "SUCCESS");
    } catch (error) {
      snackbar(t("fail.closeAssessment"), "ERROR");
      logger.error("Error while closing assessment: ", error);
    }
  };

  return {
    assessments,
    fetchAssessments,
    addNewAssessments,
    editAssessment,
    closeAssessments,
    loader: {
      isLoading,
      message: loadingMessage,
      startLoading,
      stopLoading,
    },
  };
}

export default useAssessments;
