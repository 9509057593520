import { createSlice } from "@reduxjs/toolkit";
import { Assessment } from "../../types/Assessment";
import { RootState } from "../store";

export type Collaborator = {
  id: string;
  assessmentId: string;
  userName: string;
  userEmail: string;
  role: string;
};

export type Notifier = {
  id: string;
  name: string;
  email: string;
  assessmentId: string;
};

const initialState: {
  collaborators: Collaborator[];
  notifiers: Notifier[];
  assessments: Assessment[];
} = {
  collaborators: [],
  notifiers: [],
  assessments: [],
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setAssessments(state, action) {
      state.assessments = action.payload;
    },
    addAssessment(state, action) {
      state.assessments.push(action.payload);
    },
    editAssessment(state, { payload }) {
      const id = state.assessments.findIndex(
        (a) => +a.videointerviewGroupId === +payload.videointerviewGroupId
      );
      if (id !== -1) state.assessments[id] = { ...state.assessments[id], ...payload };
    },
    closeAssessment(state, action) {},
    setCollaborators(state, action) {
      state.collaborators = action.payload;
    },
    addCollaborator({ collaborators }, action) {
      collaborators.push({
        ...action.payload,
        id: `${5001 + collaborators.length}`,
      });
    },
    removeCollaborator(state, action) {
      state.collaborators = state.collaborators.filter((c) => action.payload !== c.id);
      return state;
    },
    setNotifiers(state, action) {
      state.notifiers = action.payload;
    },
    addNotifier({ notifiers }, action) {
      notifiers.push({ ...action.payload, id: `${5001 + notifiers.length}` });
    },
    editNotifier({ notifiers }, action) {
      const id = notifiers.findIndex((q) => q.id === action.payload.id);
      notifiers[id] = { ...notifiers[id], ...action.payload };
    },
    removeNotifier(state, action) {
      state.notifiers = state.notifiers.filter((c) => action.payload !== c.id);
      return state;
    },
  },
});

export default assessmentSlice.reducer;

export const assessmentActions = { ...assessmentSlice.actions };

export const assessmentSelector = {
  assessments: (store: RootState) => store.assessment.assessments,
  collaborators: (assessmentId: string) => (store: RootState) =>
    store.assessment.collaborators.filter((c) => c.assessmentId === assessmentId),
  notifiers: (assessmentId: string) => (store: RootState) =>
    store.assessment.notifiers.filter((n) => n.assessmentId === assessmentId),
};
