import renderAdaptiveCard from "../../utils/renderAdaptiveCard";
import { startTeamsTask } from "../../utils/teamsTasks";

import notifierCard from "./NotifierCard.json";

// type NotifierParam = Notifier & { assessmentName: string };

type NotifierParam = {
  assessmentName: string;
  assessmentId: string;
  name?: string;
  email?: string;
};

const openNotifierCard = async (notifier: NotifierParam, mode: "ADD" | "EDIT" = "ADD") => {
  notifier = { name: "", ...notifier };

  const result = await startTeamsTask({
    title: mode === "ADD" ? "Create Notifier Information" : "Edit Notifier Detail",
    card: renderAdaptiveCard(notifierCard, {
      ...notifier,
      action: mode === "ADD" ? "ADD" : "EDIT",
    }),
  });

  return result;
};

export default openNotifierCard;
