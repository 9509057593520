import { InProduction } from "../Config";

const baseURL = InProduction ? "https://lb.x0pa.ai/xauth/mst" : "https://xsb.x0pa.ai/xauth/mst";
const baseAppURL = InProduction ? "https://live.x0pa.ai" : "https://staging.x0pa.ai";
const baseBeURL = InProduction ? "https://lb.x0pa.ai/xauth" : "https://xsb.x0pa.ai/xauth";

export const ON_BOARD_LINK = "https://roomv2.x0pa.ai/public/room/onboard";

// Local storage key
export const SELECTED_ASSESSMENT_ID = "selectedAssessmentId";
export const PAGE_STATE_EXPIRATION_TIME = "pageStateExpirationTime";

const TeamsThemes = {
  dark: "dark",
  default: "default",
  contrast: "contrast",
};

const ErrorCode = {
  NETWORK_NOT_REACHABLE: 5001,
  ERROR_SETTING_UP_REQUEST: 5002,
  API_ERROR: 5003,
  APPLICATION_PERMISSION_NOT_GRANTED: 5004,
};

export const BaseApiPath = "roboroy/api/v1";

export { baseURL, baseAppURL, baseBeURL, TeamsThemes, ErrorCode };
