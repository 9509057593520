import { Notifier } from "../types/Notifier";
import { xfetch } from "../services/xfetch";
import { BaseApiPath } from "../utils/constants";

const notifiedPath = `${BaseApiPath}/videointerviewnotifications`;
// TODO: add limit and skip
const getAssessmentNotifiers = async (assessmentId: number): Promise<Notifier[]> => {
  //737?_=1647409657520&limit=10&skip=0&
  const xpath = `${notifiedPath}/${assessmentId}`;
  const response = await xfetch(xpath, "get", null);
  return response.notifications;
};

const addAssessmentNotifier = async (name: string, email: string, assessmentId: string) => {
  const data = {
    videointerviewGroupId: assessmentId,
    name,
    email,
    meta: {},
  };
  return await xfetch(notifiedPath, "post", data);
};

const updateAssessmentNotifier = async (
  notificationId: string,
  name: string,
  email: string,
  assessmentId: string
) => {
  const data = {
    notificationId,
    name,
    email,
    videointerviewGroupId: assessmentId,
    meta: {},
  };
  return await xfetch(notifiedPath, "patch", data);
};

const deleteAssessmentNotifier = async (notificationId: string) => {
  return await xfetch(notifiedPath, "delete", {
    notificationId,
  });
};

const notifierAPI = {
  getAssessmentNotifiers,
  addAssessmentNotifier,
  deleteAssessmentNotifier,
  updateAssessmentNotifier,
};

export default notifierAPI;
