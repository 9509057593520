import React from "react";
import TeamsBaseComponent from "msteams-react-base-component";

import VideoPlayer from "./VideoPlayer";
import { getXToken } from "../../api/xtoken";

class XPlay extends TeamsBaseComponent {
  constructor(props) {
    super(props);
    const usp = new URLSearchParams(window.location.search);
    this.state = {
      isLoading: true,
      theme: usp.get("theme"),
    };
  }

  getXRToken = async () => {
    try {
      const res = await getXToken();
      const { t } = res || {};
      this.setState({
        xrtoken: t,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const vurl = window.location.search.replace("?xurl=", "");
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      aspectRatio: "4:3",
      width: 640,
      height: 480,
      fluid: false,
      sources: [
        {
          src: vurl,
          type: "video/webm",
        },
      ],
    };
    return (
      <div style={{ padding: 0, minHeight: "100vh" }}>
        <div style={{ maxHeight: 480, maxWidth: 640 }}>
          <VideoPlayer {...videoJsOptions} />
        </div>
      </div>
    );
  }
}

export default XPlay;
