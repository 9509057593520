import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Segment } from "@fluentui/react-northstar";
import { RootState } from "../../store/store";
import { appActions } from "../../store/slices/appSlice";

import "./Snackbar.scss";

export type SnackbarType = "INFO" | "SUCCESS" | "ERROR" | "WARNING";

export type SnackbarDuration = "SHORT" | "MEDIUM" | "LONG";

const DURATION = Object.freeze({
  SHORT: 1000,
  MEDIUM: 2000,
  LONG: 4000,
});

const SnackbarColor: { [key in SnackbarType]: string } = {
  INFO: "grey",
  ERROR: "red",
  SUCCESS: "green",
  WARNING: "orange",
};

const Snackbar = () => {
  const snackbar = useSelector((store: RootState) => store.app.snackbar);
  const dispatch = useDispatch();

  if (snackbar.show) {
    setTimeout(
      () => dispatch(appActions.hideSnackbar()),
      DURATION[snackbar.duration]
    );
  }

  if (snackbar.show) {
    return (
      <div className="snackbar">
        <Segment
          inverted
          className="snackbar-alert"
          content={snackbar.message}
          color={SnackbarColor[snackbar.type]}
        />
      </div>
    );
  }
  return null;
};

export default Snackbar;
