import { xfetch } from "../services/xfetch";
import { BaseApiPath } from "../utils/constants";

// TODO: add limit and skip
const getAssessmentCollaborators = async (assessmentId: number) => {
  //737?_=1647409657520&limit=10&skip=0&
  const xpath = `${BaseApiPath}/videointerviewgroups/collaborator/${assessmentId}`;
  const response = await xfetch(xpath, "get", null);
  return response.collaborators;
};

const getCollaboratorsUserList = async () => {
  const xpath = `${BaseApiPath}/userinviteemailmap?filter[includeMangedCompanyUsers]=true`;
  const response = await xfetch(xpath, "get", null);
  return response.users;
};

const addAssessmentCollaborator = async (assessmentId: number, user: any) => {
  const xpath = `${BaseApiPath}/videointerviewgroups/collaborator/${assessmentId}`;
  const data = {
    collaboratorId: user.userId,
    roleId: user.roleId,
    videointerviewGroupId: assessmentId,
  };
  return await xfetch(xpath, "post", data);
};

const deleteAssessmentCollaborators = async (
  assessmentId: number | string,
  collaboratorId: string
) => {
  const xpath = `${BaseApiPath}/videointerviewgroups/collaborator/${assessmentId}/${collaboratorId}`;
  return await xfetch(xpath, "delete");
};

const collaboratorAPI = {
  getAssessmentCollaborators,
  addAssessmentCollaborator,
  deleteAssessmentCollaborators,
  getCollaboratorsUserList,
};

export default collaboratorAPI;
