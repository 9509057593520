import logger from "../../services/logger";
import collaboratorCard from "./CollaboratorCard.json";
import { startTeamsTask } from "../../utils/teamsTasks";
import renderAdaptiveCard from "../../utils/renderAdaptiveCard";

const openCollaboratorCard = async (
  collaborator: { assessmentName: string; assessmentId: string },
  userList: any[]
) => {
  logger.debug("X0pa: openCollaboratorCard ", collaborator, userList);

  collaboratorCard.body[2].choices = userList?.map((user: any) => ({
    title: `${user.userInfo?.firstname} ${user.userInfo?.lastname} (${user.userInfo?.email})`,
    value: "" + user.userInfo?.userId,
  }));

  const result = await startTeamsTask({
    title: "Add New Collaborator",
    card: renderAdaptiveCard(collaboratorCard, collaborator),
  });

  return result;
};

export default openCollaboratorCard;
