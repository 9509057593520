import { createContext } from "react";

export type GraphToken = { accessToken: string; expireDate: Date; roles?: string[] };

const GraphContext = createContext<{
  graphToken: GraphToken;
  applicationLevelToken: GraphToken | null;
  resetGraphToken: () => void;
  refetchApplicationLevelToken: () => any;
}>({
  graphToken: { accessToken: "", expireDate: new Date() },
  applicationLevelToken: null,
  resetGraphToken: () => {},
  refetchApplicationLevelToken: () => {},
});

export default GraphContext;
